import axios from "axios";

const url = "https://apps.telecorpthailand.com";
export const login = (data) => {
  return axios({
    url: url + "/api/auth/login",
    method: "POST",
    data: data,
  })
    .then((res) => {
      return res.data.users;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};
export const Refrigerator = async () => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/Refrigerator",
    method: "get",
    headers: headers,
  })
    .then((res) => {
      return res.data.refrigeratorimgs;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const RefrigeratorByUid = async (id) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/Refrigerator/GetbyUID/" + id,
    method: "get",
    headers: headers,
  })
    .then((res) => {
      return res.data.refrigeratorimgs;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const RefrigeratorimgBymsrefrigeratoruid = async (id) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/Refrigeratorimg/GetbyRefID/" + id,
    method: "get",
    headers: headers,
  })
    .then((res) => {
      return res.data.refrigeratorimgs;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const RefrigeratorByMsOrganizationUid = async (id) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/Refrigerator/GetbyID/" + id,
    method: "get",
    headers: headers,
  })
    .then((res) => {
      return res.data.refrigeratorimgs;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};
export const DataByToken = async (id) => {
  return axios({
    url: url + "/api/VwUserlogin",
    method: "POST",
    data: {
      Token: id,
    },
  })
    .then((res) => {
      console.log(res);
      return res.data.msusers;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const PushnotifylogByOrganization = async (id) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/Pushnotifylog/GetbyID/" + id,
    method: "get",
    // headers:headers,
  })
    .then((res) => {
      return res.data.pushnotifylogs;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};
export const PushnotifylogByMACID = async (id) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/Pushnotifylog/GetbyMACID/" + id,
    method: "get",
    // headers:headers,
  })
    .then((res) => {
      return res.data.pushnotifylogs;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const reportToday = async (id) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/SummaryReport/Today/" + id,
    method: "get",
    // headers:headers,
  })
    .then((res) => {
      return res.data.reportTodays;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const reportToday_Humi = async (id) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/SummaryReport/Today_humi/" + id,
    method: "get",
    // headers:headers,
  })
    .then((res) => {
      return res.data.reportTodays;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const reportWeek = async (data) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/SummaryReport/Week",
    method: "post",
    // headers:headers,
    data: data,
  })
    .then((res) => {
      return res.data.reportTodays;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const reportWeek_Humi = async (data) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/SummaryReport/Week_humi",
    method: "post",
    // headers:headers,
    data: data,
  })
    .then((res) => {
      return res.data.reportTodays;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const reportMonth = async (data) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/SummaryReport/Month",
    method: "post",
    // headers:headers,
    data: data,
  })
    .then((res) => {
      return res.data.reportTodays;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const reportMonth_Humi = async (data) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/SummaryReport/Month_humi",
    method: "post",
    // headers:headers,
    data: data,
  })
    .then((res) => {
      return res.data.reportTodays;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const reportDaily = async (data) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/SummaryReport/Daily",
    method: "post",
    // headers:headers,
    data: data,
  })
    .then((res) => {
      return res.data.reportTodays;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const reportDaily_Humi = async (data) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/SummaryReport/Daily_humi",
    method: "post",
    // headers:headers,
    data: data,
  })
    .then((res) => {
      return res.data.reportTodays;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const OutofserviceByCode = async (data) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/Outofservice/ByID/" + data,
    method: "get",
    // headers:headers,
    data: data,
  })
    .then((res) => {
      return res.data.outofservices;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};
export const MaintenanceByCode = async (data) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/Maintenance/byid/" + data,
    method: "get",
    // headers:headers,
    data: data,
  })
    .then((res) => {
      return res.data.maintenances;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const putMaintenance = async (data) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/Maintenance/" + data.uid,
    method: "put",
    // headers:headers,
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const postMaintenance = async (data) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/Maintenance/",
    method: "post",
    // headers:headers,
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const putOutofservice = async (data) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/Outofservice/" + data.uid,
    method: "put",
    // headers:headers,
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const postOutofservice = async (data) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/Outofservice/",
    method: "post",
    // headers:headers,
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};
export const putRefrigerator = async (data) => {
  let token = localStorage.getItem("accessData");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  return axios({
    url: url + "/api/Refrigerator/" + data.uid,
    method: "put",
     headers:headers,
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return [];
    });
};
export const postRefrigeratorimg = async (data) => {
    let token = localStorage.getItem("accessData");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    return axios({
      url: url + "/api/Refrigeratorimg/" ,
      method: "post",
       headers:headers,
      data: data,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  };


  export const putRefrigeratorimg = async (data) => {
    let token = localStorage.getItem("accessData");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    return axios({
      url: url + "/api/Refrigeratorimg/" + data.uid,
      method: "put",
       headers:headers,
      data: data,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  };


  export const changepassword = async (data,id) => {
    let token = localStorage.getItem("accessData");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    return axios({
      url: url + "/api/auth/changepassword/" + id,
      method: "put",
       headers:headers,
      data: data,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  };
  
  export const OrganisationByuid = async (id) => {
    let token = localStorage.getItem("accessData");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    return axios({
      url: url + "/api/Organisation/byuid/" + id,
      method: "get",
      //  headers:headers,
      // data: data,
    })
      .then((res) => {
        return res.data.organisations;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  };


  export const getLocation = async () => {
    let token = localStorage.getItem("accessData");

    console.log(url + "/api/Location")
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    return axios({
      url: url + "/api/Location",
      method: "get",
     headers:headers,
      // data: data,
    })
      .then((res) => {
        return res.data.locations;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  };
  export const LocationByuid = async (id) => {
    let token = localStorage.getItem("accessData");

    console.log(url + "/api/Location/GetbyID/" + id)
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    return axios({
      url: url + "/api/Location/GetbyID/" + id,
      method: "get",
      // headers:headers,
      // data: data,
    })
      .then((res) => {
        return res.data.locations;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  };


  export const NewLocationByuid = async (id) => {
    let token = localStorage.getItem("accessData");

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    return axios({
      url: url + "/api/VwUserlogin/GetUserLocation/" + id,
      method: "get",
      // headers:headers,
      // data: data,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  };