import React, { Fragment, useEffect, useState } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { Nav } from "./Nav";
import NavBar from "./NavBar";
import { useSelector, useDispatch } from "react-redux";
import {
  temperatureAdded,
  temperatureUpdated,
  temperatureClear
} from "./../../store/temperature";
import { Refrigerator, getLocation,RefrigeratorByMsOrganizationUid, OrganisationByuid, LocationByuid, OutofserviceByCode, MaintenanceByCode, NewLocationByuid } from "./../../store/api/api";
import { userAdded } from "../../store/usersSlice";
import { Menu, Transition } from "@headlessui/react";
import { Link, useHistory } from "react-router-dom";
import mqtt from "mqtt";


const General = (props) => {

  let history = useHistory();


  const [connection, setConnection] = useState(null);
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [organization, setOrganization] = useState([]);


  let user = localStorage.getItem("access");
  try {
    user = JSON.parse(user);

  } catch (error) {
    console.log(error);
  }
  const temperatureData = useSelector((state) => state.temperature);
  let dispatch = useDispatch();
  useEffect(() => {
    loadData();
  }, []);


  // useEffect(() => {
  //   const connect = new HubConnectionBuilder()
  //     .withUrl("https://apps.telecorpthailand.com/signalRHub")
  //     .withAutomaticReconnect()
  //     .build();
  //   setConnection(connect);
  // }, []);

  // useEffect(() => {
  //   if (connection) {
  //     connection
  //       .start()
  //       .then(() => {
  //         connection.on("UpdateDashboardAsync", (message) => {
  //           // dispatch(temperatureUpdated(message));
  //           // if(message.macid == '0219'){
  //           //   console.log(message);
  //           // }

  //         });
  //       })
  //       .catch((error) => console.log(error));
  //   }
  // }, [connection]);



  async function loadData() {
    let data = localStorage.getItem("access");
    let RefrigeratorData = []
    if (!data) {
      history.push("/login");
    }
    if (user.length === 0) {
      dispatch(userAdded(JSON.parse(data)[0]));
    }
    let organization = await OrganisationByuid(JSON.parse(data)[0].msorganisationuid)
    setOrganization(organization);

    if (temperatureData.length === 0 || temperatureData.state) {
      if (JSON.parse(data)[0].msorganisationuid == "98") {
        RefrigeratorData = await Refrigerator();

      } else {
        let dataResponse = await RefrigeratorByMsOrganizationUid(JSON.parse(data)[0].msorganisationuid);
        if (JSON.parse(data)[0].type == "Admin") {
          RefrigeratorData = dataResponse
        } else {
          RefrigeratorData = dataResponse.filter((ref) => ref.mslocationuid == JSON.parse(data)[0].mslocationuid)
        }
console.log(RefrigeratorData);

        let newLocation = await NewLocationByuid(JSON.parse(data)[0].uid)
        console.log('data',data);

        if (newLocation && newLocation.length > 0) {

          // console.log('newLocation' , newLocation)
          let RefrigeratorDataForm = [];
          await newLocation.forEach(element => {
            let form_ = dataResponse.filter((ref) => ref.mslocationuid == element.locationid)
            RefrigeratorDataForm.push(...form_)
          });
          // console.log('RefrigeratorDataForm-->',RefrigeratorDataForm);
          RefrigeratorData = RefrigeratorDataForm

        }




      }

      let dataFilter = [];
      let location = await getLocation()
    
      for await (const iterator of RefrigeratorData) {

        let Outofservice = await OutofserviceByCode(iterator.code);
        let Maintenance = await MaintenanceByCode(iterator.code);

        let mslocationData = location.filter((loc) => loc.uid == iterator.mslocationuid) 
        
        await dataFilter.push({
          ...iterator,
          mslocationName: mslocationData[0]?.name ? mslocationData[0]?.name : '',
          outofservicestatus: Outofservice[0] ? Outofservice[0].outofservicestatus: "",
                    outofserviceDetail: Outofservice[0] ? Outofservice[0].detail: "",
          maintenancestatus: Maintenance[0]? Maintenance[0].maintenancestatus  : "",
                    maintenanceDetail: Maintenance[0]? Maintenance[0].detail  : "",
        })
      }

      RefrigeratorData = await dataFilter.sort(function (a, b) {
        var nameA = a.name?.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name?.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      console.log(temperatureData)
      if (temperatureData.length === 0) {
        await dispatch(temperatureAdded(RefrigeratorData));
      }
    }
  }


  const checkStatus = () => {
    let params = {
      macid: '0230',
      tempin: '7.00',
      tempout: '7.00',
    }
    dispatch(temperatureUpdated(params));
  }




  // MQTT

  const [connectionStatus, setConnectionStatus] = useState(false);
  const [messages, setMessages] = useState([]);

  // useEffect(() => {
  //   const client = mqtt.connect('http://api-temp.telecorp.co.th:9001');
  //   client.subscribe('VBH/2066')
  //   client.on("message", function (topic, payload) {
  //     console.log(topic);
  //     console.log(payload);
  //     client.end()
  //   })
  //   //     const client = mqtt.connect('http://api-temp.telecorp.co.th:9001');
  //   //     client.on('connect', () => setConnectionStatus(true));
  //   //     client.on('message', (topic, payload, packet) => {
  //   // console.log(topic, payload, packet);
  //   //     });
  // }, []);


  useEffect(() => {
    if (temperatureData.length > 0) {
      loadMQTT()
    }
  }, [temperatureData])


  const loadMQTT = () => {
    if (temperatureData.length === 0) {
      return
    }
    if (!load) {
      let client = mqtt.connect('wss://api-temp.telecorp.co.th', { host: 'api-temp.telecorp.co.th', port: 9001, username: 'admintelecorp', password: '11681168', rejectUnauthorized: false })
      client.on('connect', async (res) => {

        if (temperatureData.length == 0) {
          return
        }
        // console.log('temperatureData--->',temperatureData);

        client.subscribe(`#`)


      });
      client.on('message', async (topic, payload) => {


        //  console.log(payload.toString(),topic);
        let dataRefigurater;
        let tempin;
        let code;
        try {
          //  console.log(123,'{'+payload.toString().slice(14,1000));
          tempin = JSON.parse('{' + payload.toString().slice(14, 1000))
          code = payload.toString().slice(8, 12)
          dataRefigurater = { ...tempin, code: code }
        } catch (error) {


          //    let datapayload = JSON.parse(payload.toString())
          //   //  console.log(payload.toString());
          //    if (payload && payload.length > 0 && payload[0].mac){
          //      dataRefigurater = { ...payload[0], code: payload[0].mac } 

          // }else{
          //      dataRefigurater = JSON.parse(payload.toString())

          // }


          //  console.log(payload.toString());
          try {

            let dataPayload = JSON.parse(payload.toString())
            // console.log(dataPayload);

            dataRefigurater = dataPayload


          } catch (error1) {
            console.log(error1);
          }

        }



        if (dataRefigurater) {
          dispatch(temperatureUpdated(dataRefigurater));

        }




      })
      setLoad(true)
    }

  }



  return (
    <div className="flex flex-col w-screen h-screen overflow-hidden bg-blue-100 xl:bg-blue-900">
      <div className="justify-between hidden w-full h-24 p-2 duration-300 xl:flex">
        <div className="">
          <img src="/LUKA_Technology.png" className="w-16 h-16 " alt="" />
        </div>
        <div className="flex flex-col text-center text-white">
          <button className="px-4 py-2 bg-red-300" hidden onClick={() => checkStatus()}>Check Status</button>
          <b>{user.map((u) => u.prename + ' ' + u.forename + ' ' + u.surname)}</b>
          <div className="flex flex-row justify-center w-full">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              ></path>
              <path
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              ></path>
            </svg>
            <p className=""> {organization.map((u) => u.name)}</p>
          </div>
        </div>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              <div className="flex items-center justify-center w-16 h-16 bg-white rounded-full ">
                <img src="/LUKA_Technology.png" className=" w-14 h-14" alt="" />
              </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="z-50 w-full px-1 py-1">
                <Menu.Item>
                  {({ active }) => (
                    <div className="flex flex-row flex-wrap ">
                      <Link
                        to="/Profile"
                        className="flex flex-col items-center justify-center w-full mb-3 text-center text-gray-700 sm:w-1/2"
                      >
                        <div className="p-4 text-white rounded-full bg-luka-dark hover:bg-luka-light">
                          <svg
                            className="w-10 h-10"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokelinejoinn="round"
                              strokeWidth="2"
                              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                            ></path>
                          </svg>
                        </div>
                        <p className="text-xs text-gray-800">Profile</p>
                      </Link>
                      <Link
                        to="/ChangePassword"
                        className="flex flex-col items-center justify-center w-full mb-3 text-center text-gray-700 sm:w-1/2"
                      >
                        <div className="p-4 text-white rounded-full bg-luka-dark hover:bg-luka-light">
                          <svg
                            className="w-10 h-10"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokelinejoinn="round"
                              strokeWidth="2"
                              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                            ></path>
                            <path
                              strokeLinecap="round"
                              strokelinejoinn="round"
                              strokeWidth="2"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            ></path>
                          </svg>
                        </div>
                        <p className="text-xs text-gray-800">Change Password</p>
                      </Link>

                      <Link
                        to="/login"
                        className="flex flex-col items-center justify-center w-full mb-3 text-center text-gray-700 "
                      >
                        <div className="p-4 text-white rounded-full bg-luka-dark hover:bg-luka-light">
                          <svg
                            className="w-10 h-10"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokelinejoinn="round"
                              strokeWidth="2"
                              d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                            ></path>
                          </svg>
                        </div>
                        <p className="text-xs text-gray-800">Sing Out</p>
                      </Link>
                    </div>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <div className="bottom-0 w-full h-full overflow-auto bg-blue-100 xl:rounded-t-3xl ">
        <div className="fixed hidden w-full p-2 mb-4 xl:block">
          <button
            className="p-1 text-luka-light "
            onClick={() => setOpen(!open)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              className="bi bi-list-nested "
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </button>
        </div>
        <div className="w-full h-full">
          {open ? (
            <div className="w-full h-full duration-700"><Nav></Nav></div>
          ) : (
            <div className="w-full h-full mt-0 duration-300 xl:mt-10">{props.children}</div>
          )}
        </div>
      </div>

      {/* <div className="absolute bottom-0 flex-row hidden w-full bg-blue-100 rounded-t-xl xl:flex ">
<NavBar></NavBar>
</div> */}

    </div>
  );
};

export default General;
