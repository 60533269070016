import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import General from './structure/General';
import { NavLink } from "react-router-dom";
const Home = () => {


  // const posts = useSelector((state) => state.post);
  // const user = useSelector((state) => console.log(state));
  // console.log(user);


  return (
    
    <div className="w-full h-full">
      <nav className="w-full h-screen bg-blue-100">
        <div className="container p-6 mx-auto">
          <h1 className="block text-2xl font-bold text-center text-luka-dark dark:text-white lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300">
            Menu
          </h1>

          {/* <div className="flex items-center justify-center mt-6 text-gray-600 capitalize dark:text-gray-300">
                        <NavLink to="/Home" activeClassName=" border-b-2 border-blue-500" className="border-b-2 border-transparent hover:text-gray-800 dark:hover:text-gray-200 hover:border-blue-500 mx-1.5 sm:mx-6" >
                            home
                        </NavLink>
                        <NavLink to="/Monitor" activeClassName=" border-b-2 border-blue-500" className="border-b-2 border-transparent hover:text-gray-800 dark:hover:text-gray-200 hover:border-blue-500 mx-1.5 sm:mx-6" >
                            Monitor
                        </NavLink>
                        <NavLink to="/login" activeClassName=" border-b-2 border-blue-500" className="border-b-2 border-transparent hover:text-gray-800 dark:hover:text-gray-200 hover:border-blue-500 mx-1.5 sm:mx-6" >
                            Login
                        </NavLink>
                    </div> */}

      
            <div className="w-full px-7 ">
              <div className="flex flex-wrap items-center justify-between w-full">
                <div className="w-1/2 group">
                  <NavLink
                    to="/Monitor"
                    activeClassName="text-indigo-500"
                    className="flex items-end justify-center w-full px-4 pt-2 mx-auto text-center text-gray-400 group-hover:text-luka-light sm:w-1/2 md:w-1/3"
                  >
                    <span className="flex flex-col items-center justify-center w-full px-1 pt-1 pb-1">
                 <div className="flex items-center justify-center w-full h-20 p-2 mb-3 text-white rounded-lg bg-luka-light ">
             
          <img src="/images/mMonitor.png"     className="block h-10 pt-1 mb-1 text-2xl w-15" alt="" />
                 </div>
                      {/* <i className="block w-10 h-10 pt-1 mb-1 text-2xl far fa-home"></i> */}
                      <span className="block h-16 pb-2 text-xs text-black">
                        Monitor All Views
                      </span>
                      <span className="block w-5 h-1 mx-auto rounded-full group-hover:bg-luka-light"></span>
                    </span>
                  </NavLink>
                </div>
 
                <div className="w-1/2 group">
                  <NavLink
                    to="/servicecompany"
                    activeClassName="text-indigo-500"
                    className="flex items-end justify-center w-full px-4 pt-2 mx-auto text-center text-gray-400 group-hover:text-luka-light sm:w-1/2 md:w-1/3"
                  >
                    <span className="flex flex-col items-center justify-center w-full px-1 pt-1 pb-1">
                    <div className="flex items-center justify-center w-full h-20 p-2 mb-3 text-white rounded-lg bg-luka-light ">
             <img src="/images/mRepair.png"     className="block h-10 pt-1 mb-1 text-2xl w-15" alt="" />
             </div>
                      {/* <i className="block w-10 h-10 pt-1 mb-1 text-2xl far fa-compass"></i> */}
                      <span className="block h-16 pb-2 text-xs text-black">
                        Service Setup (company)
                      </span>
                      <span className="block w-5 h-1 mx-auto rounded-full group-hover:bg-luka-light"></span>
                    </span>
                  </NavLink>
                </div>
                <div className="w-1/2 group">
                  <NavLink
                to="/service"
                    activeClassName="text-indigo-500"
                    className="flex items-end justify-center w-full px-4 pt-2 mx-auto text-center text-gray-400 group-hover:text-luka-light sm:w-1/2 md:w-1/3"
                  >
                    <span className="flex flex-col items-center justify-center w-full px-1 pt-1 pb-1">
                    <div className="flex items-center justify-center w-full h-20 p-2 mb-3 text-white rounded-lg bg-luka-light ">
              <img src="/images/mServiceCompany.png"     className="block h-10 pt-1 mb-1 text-2xl w-15" alt="" />

              </div>
                      {/* <i className="block w-10 h-10 pt-1 mb-1 text-2xl far fa-cog"></i> */}
                      <span className="block h-16 pb-2 text-xs text-black">
                      Repair & Maintenance Service
                      </span>
                      <span className="block w-5 h-1 mx-auto rounded-full group-hover:bg-luka-light"></span>
                    </span>
                  </NavLink>
                </div>

                <div className="w-1/2 group">
                  <NavLink
                    to="/report/menu"
                    activeClassName="text-indigo-500"
                    className="flex items-end justify-center w-full px-4 pt-2 mx-auto text-center text-gray-400 group-hover:text-luka-light sm:w-1/2 md:w-1/3"
                  >
                    <span className="flex flex-col items-center justify-center w-full px-1 pt-1 pb-1">
                    <div className="flex items-center justify-center w-full h-20 p-2 mb-3 text-white rounded-lg bg-luka-light ">
              <img src="/images/mReport.png"     className="block h-10 pt-1 mb-1 text-2xl w-15" alt="" />

              </div>

                      {/* <i className="block w-10 h-10 pt-1 mb-1 text-2xl far fa-cog"></i> */}
                      <span className="block h-16 pb-2 text-xs text-black">Summary Report</span>
                      <span className="block w-5 h-1 mx-auto rounded-full group-hover:bg-luka-light"></span>
                    </span>
                  </NavLink>
                </div>
              </div>

              
            </div>
 
        </div>
      </nav>
    </div>
  );
}

export default Home
