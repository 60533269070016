import {
  RefrigeratorByMsOrganizationUid,
  PushnotifylogByOrganization,
  PushnotifylogByMACID,
  OutofserviceByCode,
  MaintenanceByCode,
  putMaintenance,
  postMaintenance,
  putOutofservice,
  postOutofservice,
  OrganisationByuid
  , Refrigerator
  , NewLocationByuid
  , getLocation
} from "./../../store/api/api";
import { useParams } from "react-router-dom";
import React, { useEffect, useState, Fragment } from "react";
import moment from "moment";
import { Dialog, Transition } from "@headlessui/react";




const Service = () => {
  let user = localStorage.getItem("access");
  try {
    user = JSON.parse(user);
  } catch (error) {
    console.log(error);
  }

  let { id } = useParams();
  // const [data, setData] = useState([]);
  // const [dataAlarm, setDataAlarm] = useState([]);
  // const [alarm, setAlarm] = useState([]);
  const [refrigerator, setRefrigerator] = useState([]);
  const [macid, setMacid] = useState(0);

  const [RepairData, setRepairData] = useState([]);
  const [MaintenanceData, setMaintenanceData] = useState([]);

  const [isModalMaintenance, setIsModalMaintenance] = useState(false);
  const [isModalMaintenanceData, setIsModalMaintenanceData] = useState(false);
  const [isModalRepair, setIsModalRepair] = useState(false);
  const [isModalRepairData, setIsModalRepairData] = useState(false);

  const [modalData, setModalData] = useState([]);
  const [form, setForm] = useState({});
  const [load, setLoad] = useState(false);
  useEffect(() => {
    loadData();
  }, []);


  // ModalMaintenance
  const openModalMaintenance = async (data) => {
    let res = await MaintenanceByCode(data.code);
    console.log(res);
    if (res.length > 0) {
      setForm(res[0]);
    } else {
      let param = {
        cwhen: new Date(),
        datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        detail: "",
        flg: 1,
        maintenancestatus: "",
        refrigcode: data.code,
        uid: 0,
      };
      console.log(param);
      setForm(param);
    }
    setIsModalMaintenance(true);
  };
  const closeModalMaintenance = () => {
    setForm({});
    setIsModalMaintenance(false);
  };
  const onSaveMaintenance = async (data) => {
    let param = {
      cwhen: new Date(),
      datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      detail: form.detail,
      flg: 1,
      maintenancestatus: data,
      refrigcode: form.refrigcode,
      uid: form.uid ? form.uid : 0,
    };

    if (param.uid == 0) {
      let res = await postMaintenance(param);

      loadData();
      closeModalMaintenance();
    } else {
      let res = await putMaintenance(param);

      loadData();
      closeModalMaintenance();
    }
  };
  const openModalMaintenanceData = async (data) => {
    let res = await MaintenanceByCode(data.code);
    console.log(res);
    let data_ = [];
    for await (const iterator of res) {
      data_.push({ ...iterator, name: data.name, code: data.code });
    }
    setModalData(data_);

    setIsModalMaintenanceData(true);
  };
  const closeModalMaintenanceData = () => {
    setIsModalMaintenanceData(false);
  };

  //ModalRepair
  const openModalRepair = async (data) => {
    console.log(data);
    let res = await OutofserviceByCode(data.code);
    console.log(res);
    if (res.length > 0) {
      setForm(res[0]);
    } else {
      let param = {
        cwhen: new Date(),
        datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        detail: "",
        flg: 1,
        msorganisationuid: data.msorganisationuid,
        outofservicestatus: "",
        refrigcode: data.code,
        uid: 0,
      };
      setForm(param);
    }

    // OutofserviceByCode()
    setIsModalRepair(true);
  };
  const onSaveRepair = async () => {
    console.log(form);
    let param = {
      cwhen: new Date(),
      datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      detail: form.detail,
      flg: 1,
      msorganisationuid: form.msorganisationuid.toString(),
      outofservicestatus: "Maintenance",
      refrigcode: form.refrigcode,
      uid: form.uid,
    };
    if (param.uid == 0) {
      let res = await postOutofservice(param)
      loadData();
      closeModalRepair();
    } else {
      let res = await putOutofservice(param)
      loadData();
      closeModalRepair();
    }

  }
  const closeModalRepair = () => {
    setIsModalRepair(false);
  };
  const openModalRepairData = async (data) => {
    let res = await OutofserviceByCode(data.code);
    console.log(res);
    let data_ = [];
    for await (const iterator of res) {
      data_.push({ ...iterator, name: data.name, code: data.code });
    }
    setModalData(data_);
    setIsModalRepairData(true);
  };
  const closeModalRepairData = () => {
    setIsModalRepairData(false);
  };


  const loadData = async () => {
    setLoad(true);
    let data__ = await loadRefrigerator();
    console.log('data__---->', data__);
    if (id !== 0) {
      console.log(1);
    } else {
      await loaddata(0, data__);
      console.log(2);
    }
    setTimeout(() => {
      setLoad(false);
    }, 1000);

  };
  const loadRefrigerator = async () => {
    let data = localStorage.getItem("access");
    let RefrigeratorData = []

    let organization = await OrganisationByuid(JSON.parse(data)[0].msorganisationuid)


    if (JSON.parse(data)[0].msorganisationuid == "98") {
      RefrigeratorData = await Refrigerator();

    } else {
      let dataResponse = await RefrigeratorByMsOrganizationUid(JSON.parse(data)[0].msorganisationuid);
      if (JSON.parse(data)[0].type == "Admin") {
        RefrigeratorData = dataResponse
      } else {
        RefrigeratorData = dataResponse.filter((ref) => ref.mslocationuid == JSON.parse(data)[0].mslocationuid)
      }
      let newLocation = await NewLocationByuid(JSON.parse(data)[0].uid)
      console.log('data', data);

      if (newLocation && newLocation.length > 0) {

        // console.log('newLocation' , newLocation)
        let RefrigeratorDataForm = [];
        await newLocation.forEach(element => {
          let form_ = dataResponse.filter((ref) => ref.mslocationuid == element.locationid)
          RefrigeratorDataForm.push(...form_)
        });
        // console.log('RefrigeratorDataForm-->',RefrigeratorDataForm);
        RefrigeratorData = RefrigeratorDataForm

      }
    }

    let dataFilter = [];
    let location = await getLocation()

    for await (const iterator of RefrigeratorData) {

      let Outofservice = await OutofserviceByCode(iterator.code);
      let Maintenance = await MaintenanceByCode(iterator.code);

      let mslocationData = location.filter((loc) => loc.uid == iterator.mslocationuid)

      await dataFilter.push({
        ...iterator,
        mslocationName: mslocationData[0]?.name ? mslocationData[0]?.name : '',
        outofservicestatus: Outofservice[0]
          ? Outofservice[0].outofservicestatus
          : "",
        maintenancestatus: Maintenance[0]
          ? Maintenance[0].maintenancestatus
          : "",
      })
    }

    RefrigeratorData = await dataFilter.sort(function (a, b) {
      var nameA = a.name?.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name?.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    setRefrigerator(RefrigeratorData)
    return RefrigeratorData;

  }

  const loaddata = async (macid, refrigeratorData) => {
    if (user) {
      // setDataAlarm([]);
      let resAlarm = [];
      if (macid == 0) {
        resAlarm = await PushnotifylogByOrganization(
          user[0]?.msorganisationuid
        );
      } else {
        resAlarm = await PushnotifylogByMACID(macid);
      }
      console.log('resAlarm ------>', resAlarm);
      let data_ = [];

      for await (const iterator of resAlarm) {
        let data__ = refrigeratorData.find((d) => d.code === iterator.macid);
        console.log(iterator.code);
        let Outofservice = await OutofserviceByCode(iterator.code);
        console.log(Outofservice);
        data_.push({
          ...iterator,
          name: data__.name,
          cwhen: moment(data__.cwhen).format("DD-MM-YYYY hh:mm:ss"),
        });
      }
      console.log(data_);
      // setDataAlarm(data_);
      // setAlarm(data_);
    }
  };
  return (
    <div className="p-4 ">
      {load ? (
        <div className="flex flex-col items-center justify-center w-full">
          <span className="mr-5">loading...</span>

          <svg className="w-5 h-5 mr-3 -ml-1 text-black animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      ) : (
        <div className="flex flex-col flex-wrap p-4 bg-white rounded-lg">
          <div className="w-full p-2">
            <div className="flex-1 mb-4 mr-4">
              <label htmlFor="temperature"></label>
              <select
                name="temperature"
                id=""
                className="w-full h-full px-4 py-2 border rounded-lg border-luka-dark text-luka-dark"
                onChange={(e) => {
                  setMacid(e.target.value);
                  loaddata(e.target.value, refrigerator);
                }}
                value={macid}
              >
                <option value="0">Select</option>
                {refrigerator.map((data, index) => (
                  <option key={index} value={data.code}>
                    {data.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="container mx-auto ">
            <h1 className="font-extrabold text-center text-luka-light">
              Refrigerator
            </h1>

            {refrigerator.map((d, index) => (
              <div className="w-full p-2 mb-4 text-center border-b-2" key={d.uid}>
                <div className="flex justify-between w-full">
                  <b className="text-xs font-bold sm:text-base">
                    {d.code} | {d.name}
                  </b>
                  <b className="text-xs font-bold sm:text-base">{d.cwhen}</b>
                </div>
                <div className="w-full">
                  <ul className="text-xs text-left">
                    <li className="flex flex-col items-center justify-between w-full md:flex-row">
                      <div className="w-full text-left md:w-1/2">
                        Repair Status: <b>{d.outofservicestatus}</b>
                      </div>
                      <div className="w-full text-left md:w-1/2">
                        <div className="flex flex-row justify-end">
                          <span> Repair :</span>
                          <div className="p-1">
                            <button
                              onClick={() => openModalRepair(d)}
                              className="px-4 py-1 text-white bg-yellow-600 rounded"
                            >
                              Repair
                            </button>
                          </div>
                          <div className="p-1">
                            <button
                              onClick={() => openModalRepairData(d)}
                              className={`text-white  rounded px-4 py-1 ${d.outofservicestatus
                                ? "bg-blue-600"
                                : "bg-gray-400"
                                }`}
                              disabled={!d.outofservicestatus}
                            >
                              Detail
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="flex flex-col items-center justify-between w-full md:flex-row">
                      <div className="w-full text-left md:w-1/2">
                        Maintenance Status : <b>{d.maintenancestatus}</b>
                      </div>
                      <div className="w-full text-left md:w-1/2">
                        <div className="flex flex-row justify-end">
                          <span> Maintenance :</span>
                          <div className="p-1">
                            <button
                              onClick={() => openModalMaintenance(d)}
                              className="px-4 py-1 text-white bg-red-600 rounded"
                            >
                              Repair
                            </button>
                          </div>
                          <div className="p-1">
                            <button
                              onClick={() => openModalMaintenanceData(d)}
                              className={`text-white  rounded px-4 py-1 ${d.maintenancestatus
                                ? "bg-blue-600"
                                : "bg-gray-400"
                                }`}
                              disabled={!d.maintenancestatus}
                            >
                              Detail
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <>
        <Transition appear show={isModalMaintenance} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={() => closeModalMaintenance()}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white border shadow-xl border-luka-light rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Maintenance |{" "}
                    <b className="text-xs">status : {form.maintenancestatus}</b>
                  </Dialog.Title>
                  <div className="mt-2">
                    <textarea
                      className="w-full p-2 border rounded-lg border-luka-light"
                      id=""
                      cols="30"
                      rows="10"
                      value={form.detail}
                      onChange={(e) =>
                        setForm({ ...form, detail: e.target.value })
                      }
                    ></textarea>
                  </div>

                  <div className="flex justify-end">
                    <div className="p-1">
                      <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-500 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={() => onSaveMaintenance("Pass")}
                      >
                        Pass
                      </button>
                    </div>

                    <div className="p-1">
                      <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-yellow-500 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={() => onSaveMaintenance("Fail")}
                      >
                        Fail
                      </button>
                    </div>

                    <div className="p-1">
                      <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={() => onSaveMaintenance("Repair")}
                      >
                        Repair
                      </button>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </>
      <>
        <Transition appear show={isModalMaintenanceData} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={closeModalMaintenanceData}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white border shadow-xl border-luka-light rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="mb-5 text-xl font-bold leading-6 text-luka-light"
                  >
                    Detail Maintenance
                  </Dialog.Title>
                  <div className="mt-2">
                    {modalData.map((d) => (
                      <ul className="" key={d.uid}>
                        <li>
                          <h1 className="text-lg font-bold ">
                            {d.code} | {d.name}
                          </h1>
                        </li>
                        <li className="p-3 text-xs">
                          Status: <b>{d.maintenancestatus}</b>
                        </li>
                        <li className="p-3 text-xs">
                          {/* Date: <b>{moment(new Date()).format("YYYY-MM-DD")}</b> */}
                          Date: <b>{d.datetime}</b>
                        </li>
                        <li className="p-3 text-xs">
                          Detail: <b>{d.detail}</b>
                        </li>
                      </ul>
                    ))}
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={closeModalMaintenanceData}
                    >
                      close
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </>

      <>
        <Transition appear show={isModalRepair} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={() => closeModalRepair()}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white border shadow-xl border-luka-light rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-luka-light"
                  >
                    Repair
                  </Dialog.Title>

                  <div className="mt-2">
                    <textarea
                      className="w-full p-2 border rounded-lg border-luka-light"
                      id=""
                      cols="30"
                      rows="10"
                      value={form.detail}
                      onChange={(e) =>
                        setForm({ ...form, detail: e.target.value })
                      }
                    ></textarea>
                  </div>

                  <div className="flex justify-end">
                    <div className="p-1">
                      <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-luka-dark hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={() => onSaveRepair()}
                      >
                        Repair
                      </button>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </>

      <>
        <Transition appear show={isModalRepairData} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={closeModalRepairData}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white border shadow-xl border-luka-light rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="mb-5 text-xl font-bold leading-6 text-luka-light"
                  >
                    Detail Repair
                  </Dialog.Title>
                  <div className="mt-2">
                    {modalData.map((d) => (
                      <ul className="" key={d.uid}>
                        <li>
                          <h1 className="text-lg font-bold ">
                            {d.code} | {d.name}
                          </h1>
                        </li>
                        <li className="p-3 text-xs">
                          Status: <b>{d.outofservicestatus}</b>
                        </li>
                        <li className="p-3 text-xs">
                          {/* Date: <b>{moment(new Date()).format("YYYY-MM-DD")}</b> */}
                          Date: <b>{d.datetime}</b>
                        </li>
                        <li className="p-3 text-xs">
                          Detail: <b>{d.detail}</b>
                        </li>
                      </ul>
                    ))}
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={closeModalRepairData}
                    >
                      close
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </>
    </div>
  );
};

export default Service;
