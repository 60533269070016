import { configureStore,  } from "@reduxjs/toolkit";
import postsReducer  from "./postApi"
import usersReducer from './usersSlice'
import temperatureReducer from './temperature'
export default function store() {
    return configureStore({
        reducer:{
            post:postsReducer,
            users: usersReducer,
            temperature:temperatureReducer,
        },
    });
}
