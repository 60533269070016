import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { CSVLink } from "react-csv";
import {
  reportToday, 
  reportToday_Humi,
  RefrigeratorByMsOrganizationUid,
  OrganisationByuid,
  Refrigerator,
  NewLocationByuid,
  getLocation,
} from "../../store/api/api";
import moment from "moment";

import Chart from "react-apexcharts";

const Today = () => {
  let user = localStorage.getItem("access");
  try {
    user = JSON.parse(user);
  } catch (error) {
    console.log(error);
  }
  const moment = require('moment');
  require('moment/locale/th');
  moment.updateLocale('th', {
    longDateFormat: {
      L: "D MMMM YYYY",
      LT: "D MMMM YYYY HH:mm:ss",
    },
  });
  const [csvData, setCsvData] = useState([]);
  const [refrigerator, setRefrigerator] = useState([]);
  const [loadStatus, setLoadStatus] = useState(false);
  const Tabs = ['Temperature', 'Humidity']
  const [tab, setTab] = useState(0)
  const currentDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm");
  const momentDateTime = moment(currentDateTime, "YYYY-MM-DD HH:mm");
  momentDateTime.add(543, 'years');
  const formattedDate = momentDateTime.locale('th').format("D MMMM YYYY");
  const formattedTime = momentDateTime.format("HH:mm");
  const [chartData, setChartData] = useState({
    series: [{
      name: "Temperature",
      data: []
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: true,
        }
      },
      colors: ['#004481'],
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: 'ข้อมูล ณ วันที่ ' + formattedDate + ' เวลา ' + formattedTime + ' น.',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: [],
      }
    },


  });
  const [time, setTime] = useState([]);
  // const [ftempin, setFtempin] = useState([]);
  const [data, setData] = useState({});
  const [macid, setMacid] = useState("0");

  let { id } = useParams();

  useEffect(() => {
    const load = async () => {

      let data__ = await loadRefrigerator();
      if (id == 0) {

      } else {
        setMacid(id);
        if (tab === 0) {
          await loadDataTemperature(id);
        }
        else {
          await loadDataHumidity(id);
        }
      }

    };
    load();
  }, [id]);

  const loadDataTemperature = async (macid) => {
      setLoadStatus(true)

      setTime([]);
      let res = await reportToday(macid);
      let time_ = [];
      let ftempin = [];
      let lableChart = [];
      let valueChart = [];
      for (const iterator of res) {
        time_.push({ x: iterator.time, y: iterator.ftempin });
        ftempin.push(iterator.ftempin);
        lableChart.push(iterator.time.slice(10).slice(0, 6))
        valueChart.push(iterator.ftempin)
      }
      
      setTime(time_);
      setData({
        datasets: [
          {
            label: "รายงานประจำวัน",
            data: time_,
            fill: false,
            backgroundColor: "#19578d",
            borderColor: "#19578d",
          },
        ],
      });
  
      const datetimeString = time_.map((item) => ({
        Date: item.x.split(' ')[0], 
        Time: item.x.split(' ')[1].split(':').slice(0, 2).join(':'), 
        Temperature: item.y 
      }));
      setCsvData([...datetimeString]);
      setChartData({ ...chartData, series: [{ ...chartData.series, data: time_ }], options: { ...chartData.options, xaxis: { categories: lableChart } }, })
      setLoadStatus(false)
  };

  const loadDataHumidity = async (macid) => {
    setLoadStatus(true)

    setTime([]);
    let res = await reportToday_Humi(macid);
    let time_ = [];
    let fhumi = [];
    let lableChart = [];
    let valueChart = [];
    for (const iterator of res) {
      time_.push({ x: iterator.time, y: iterator.fhumi });
      fhumi.push(iterator.fhumi);
      lableChart.push(iterator.time.slice(10).slice(0, 6))
      valueChart.push(iterator.fhumi)
    }
    
    setTime(time_);
    setData({
      datasets: [
        {
          label: "รายงานประจำวัน",
          data: time_,
          fill: false,
          backgroundColor: "#19578d",
          borderColor: "#19578d",
        },
      ],
    });
    const datetimeString = time_.map((item) => ({
      Date: item.x.split(' ')[0], 
      Time: item.x.split(' ')[1].split(':').slice(0, 2).join(':'), 
      Humidity: item.y
    }));
    setCsvData([...datetimeString]);
    setChartData({ ...chartData, series: [{ ...chartData.series, data: time_ }], options: { ...chartData.options, xaxis: { categories: lableChart } }, })
    setLoadStatus(false)

  };


  const loadRefrigerator = async () => {
    let data = localStorage.getItem("access");
    let RefrigeratorData = []

    let organization = await OrganisationByuid(JSON.parse(data)[0].msorganisationuid)


    if (JSON.parse(data)[0].msorganisationuid == "98") {
      RefrigeratorData = await Refrigerator();

    } else {
      let dataResponse = await RefrigeratorByMsOrganizationUid(JSON.parse(data)[0].msorganisationuid);
      if (JSON.parse(data)[0].type == "Admin") {
        RefrigeratorData = dataResponse
      } else {
        RefrigeratorData = dataResponse.filter((ref) => ref.mslocationuid == JSON.parse(data)[0].mslocationuid)
      }
      let newLocation = await NewLocationByuid(JSON.parse(data)[0].uid)
      // console.log('data', data);

      if (newLocation && newLocation.length > 0) {

        // console.log('newLocation' , newLocation)
        let RefrigeratorDataForm = [];
        await newLocation.forEach(element => {
          let form_ = dataResponse.filter((ref) => ref.mslocationuid == element.locationid)
          RefrigeratorDataForm.push(...form_)
        });
        // console.log('RefrigeratorDataForm-->',RefrigeratorDataForm);
        RefrigeratorData = RefrigeratorDataForm

      }
    }

    let dataFilter = [];
    let location = await getLocation()

    for await (const iterator of RefrigeratorData) {

      let mslocationData = location.filter((loc) => loc.uid == iterator.mslocationuid)

      await dataFilter.push({
        ...iterator,
        mslocationName: mslocationData[0]?.name ? mslocationData[0]?.name : '',

      })
    }

    RefrigeratorData = await dataFilter.sort(function (a, b) {
      var nameA = a.name?.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name?.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    setRefrigerator(RefrigeratorData)
    return RefrigeratorData;

  }
  const handlechange_BTtemp = (index, text) => {
    setTab(index);

    if (macid != "0") {
      if (text === 'Temperature') {
        loadDataTemperature(macid);
      } else {
        loadDataHumidity(macid);
      }
    }
  }
  
  return (
    <div>
      <div className='p-4 z-20 w-full flex justify-between items-center gap-2 pb-2'>
        <div className="flex gap-2">
          {Tabs.map((text, index) => (
            <button
              key={index}
              className={`bg-transparent hover:bg-blue-500 text-white-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded ${tab === index ? 'bg-blue-500 text-white' : ''}`}
              onClick={() => {
                handlechange_BTtemp(index, text)
              }}
            >
              {text}
            </button>
          ))}
        </div>
        {!loadStatus && csvData.length > 0 && (
          <CSVLink
            data={csvData}
            filename={`Chart_Daily_${tab === 0 ? "Temperature" : "Humidity"}.csv`}
          >
            <button className={`bg-transparent hover:bg-blue-500 text-white-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded`}>
              Download CSV
            </button>
          </CSVLink>
        )}
      </div>

      {tab === 0 ? (
        <div>
          <div className="w-full p-4 overflow-auto">
            <div className="flex flex-col flex-wrap p-4 bg-white">
              <div className="flex flex-row flex-wrap w-full p-4 mb-4 ">
                <div className="flex flex-col w-full p-2">
                  <label htmlFor="temperature">temperature</label>
                  <select
                    name="temperature"
                    id="temperature"
                    className="w-full h-full px-4 py-2 border rounded-lg border-luka-dark text-luka-dark"
                    onChange={(e) => {
                      setMacid(e.target.value);
                      loadDataTemperature(e.target.value);
                    }}
                    value={macid}
                  >
                    <option value="0">Select</option>
                    {refrigerator.map((data, index) => (
                      <option key={index} value={data.code}>
                        {data.code} | {data.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <h1>รายงานประจำวัน</h1>
              {loadStatus ? <div className="flex justify-center text-center bg-opacity-50">
                <span className="w-full rounded-md">
                  <button type="button" className="inline-flex items-center px-4 py-2 text-base font-medium leading-6 transition duration-150 ease-in-out bg-yellow-500 border border-transparent rounded-md cursor-not-allowed active:bg-gray-700" disabled="">
                    <svg className="w-5 h-5 mr-3 -ml-1 animate-spin " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing
                  </button>
                </span>
              </div> : <div className="bg-white ">
                <Chart
                  key={Math.random()}
                  options={chartData.options}
                  series={chartData.series}
                  type="line"
                  height={400}
                />
              </div>}

            </div>
            <div id="wrapper">
              <div id="chart-line2">

              </div>

            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="w-full p-4 overflow-auto">
            <div className="flex flex-col flex-wrap p-4 bg-white">
              <div className="flex flex-row flex-wrap w-full p-4 mb-4 ">
                <div className="flex flex-col w-full p-2">
                  <label htmlFor="Humidity">Humidity</label>
                  <select
                    name="Humidity"
                    id="Humidity"
                    className="w-full h-full px-4 py-2 border rounded-lg border-luka-dark text-luka-dark"
                    onChange={(e) => {
                      setMacid(e.target.value);
                      loadDataHumidity(e.target.value);
                    }}
                    value={macid}
                  >
                    <option value="0">Select</option>
                    {refrigerator.map((data, index) => (
                      <option key={index} value={data.code}>
                        {data.code} | {data.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <h1>รายงานประจำวัน</h1>
              {loadStatus ? <div className="flex justify-center text-center bg-opacity-50">
                <span className="w-full rounded-md">
                  <button type="button" className="inline-flex items-center px-4 py-2 text-base font-medium leading-6 transition duration-150 ease-in-out bg-yellow-500 border border-transparent rounded-md cursor-not-allowed active:bg-gray-700" disabled="">
                    <svg className="w-5 h-5 mr-3 -ml-1 animate-spin " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Processing
                  </button>
                </span>
              </div> : <div className="bg-white ">
                <Chart
                  key={Math.random()}
                  options={chartData.options}
                  series={chartData.series}
                  type="line"
                  height={400}
                />
              </div>}

            </div>
            <div id="wrapper">
              <div id="chart-line2">

              </div>

            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Today;
