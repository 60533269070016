import {
  RefrigeratorByMsOrganizationUid,
  PushnotifylogByOrganization,
  PushnotifylogByMACID,
} from "./../../store/api/api";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
const AlarmList = () => {
  let user =  localStorage.getItem("access");
  try {
    user =  JSON.parse(user) ;
     
   } catch (error) {
     console.log(error);
   }
 
  let { id } = useParams();
  // const [data, setData] = useState([]);
  // const [dataAlarm, setDataAlarm] = useState([]);
  const [alarm, setAlarm] = useState([]);
  const [refrigerator, setRefrigerator] = useState([]);
  const [macid, setMacid] = useState(0);
  const [loadStatus, setloadStatus] = useState(false);

  useEffect(() => {
    const load = async () => {
 
      let data__ = await loadRefrigerator();
      console.log(data__);
      console.log(id);
      if ( Number(id) !==0) {
        console.log(id);
        if (data__.length > 0) {
          let data_ = await data__.find((d) => d.uid === Number(id));
          await console.log(data_);
          setMacid(data_.code);
          await loaddata(data_.code,data__);
        }
      } else {
        await loaddata(0,data__);
      }
    };
    load();
  }, []);

  const loadRefrigerator = async () => {
    
    let res = await RefrigeratorByMsOrganizationUid(user[0]?.msorganisationuid);
    console.log(res);
    setRefrigerator(res.sort((a,b)=>a.uid-b.uid));
    return res;
  };

  const loaddata = async (macid,refrigeratorData) => {
    setloadStatus(true)
    if (user) {
      // setDataAlarm([]);
      let resAlarm = [];
      if (macid == 0) {
        resAlarm = await PushnotifylogByOrganization(user[0]?.msorganisationuid);
      } else {
        resAlarm = await PushnotifylogByMACID(macid);
      }
      console.log(refrigeratorData);
      let data_ = [];
 
      for await (const iterator of resAlarm) {
        let data__ = refrigeratorData.find((d) => d.code === iterator.macid);
        data_.push({
          ...iterator,
          name: data__.name,
          // cwhen: moment(data__.cwhen).format("DD-MM-YYYY hh:mm:ss"),
        });
      }
console.log(data_);
      // setDataAlarm(data_);
      setAlarm(data_);
    setloadStatus(false)

    }
  };

  return (
    <div className="p-4 ">

{loadStatus?       <div className="flex justify-center text-center bg-opacity-50">
    <span className="w-full rounded-md">
      <button type="button" className="inline-flex items-center px-4 py-2 text-base font-medium leading-6 transition duration-150 ease-in-out bg-yellow-500 border border-transparent rounded-md cursor-not-allowed active:bg-gray-700" disabled="">
        <svg className="w-5 h-5 mr-3 -ml-1 animate-spin " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        Processing
      </button>
    </span>
  </div>:
     <div className="flex flex-col flex-wrap p-4 bg-white rounded-lg">
     <div className="w-full p-2">
       <div className="flex-1 mb-4 mr-4">
         <label htmlFor="temperature"></label>
         <select
           name="temperature"
           id=""
           className="w-full h-full px-4 py-2 border rounded-lg border-luka-dark text-luka-dark"
           onChange={(e) => {
             setMacid(e.target.value);
       loaddata(e.target.value,refrigerator)
           }}
           value={macid}
         >
           <option value="0">Select</option>
           {refrigerator.map((data, index) => (
             <option key={index} value={data.code}>
               {data.name}
             </option>
           ))}
         </select>
       </div>
     </div>

     <div className="container mx-auto ">
       <h1 className="font-extrabold text-center text-luka-light">
         Refrigerator
       </h1>

       {alarm.length > 0 ? (
         alarm.map((d, index) => (
   <Link to={`/refrigerator/${d.refriguid}`}   key={d.uid}   className="w-full p-2 mb-4 text-center border-b-2">
      
             <div className="flex justify-between w-full">
               <b className="text-xs font-bold sm:text-base">{d.name}</b>
               <b className="text-xs font-bold sm:text-base">{d.cwhen}</b>
             </div>
             <div className="w-full">
               <ul className="text-xs text-left">
                 <li>
                   Alarm for: <b>Temp In</b>
                 </li>
                 {/* <li>
                   Description Log: <b>Normal</b>
                 </li>
                 <li>
                   Risk Level: <b>Emergency</b>
                 </li> */}
                 <li>
                   message: <b>{d.messageAlarm}</b>
                 </li>
               </ul>
             </div>
         
   </Link>
           ))
       ) : (
         <div className="mt-5 text-xs text-center text-luka-light">
           ไม่พบข้อมูล
         </div>
       )}
     </div>
   </div>

}
   </div>
  );
};

export default AlarmList;
