import React, { useState, useEffect } from "react";
import {
  RefrigeratorimgBymsrefrigeratoruid,
  RefrigeratorByUid,
  putRefrigerator,
  postRefrigeratorimg,
  putRefrigeratorimg,
} from "../../store/api/api";
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2'
const EditRefrigerator = () => {
  let { id } = useParams();
  const [form, setForm] = useState({
    accuracy: "",
    accuracyhumid: null,
    band: "",
    code: "",
    contact: "",
    description: "",
    emailcontact: null,
    hnno: "",
    hw_gateway_code: null,
    idcode: "",
    macaddress: "",
    maxhumid: "",
    maxhumidout: "",
    maxrang: "",
    maxrangbeware: "",
    maxrangout: "",
    minhumid: "",
    minhumidout: "",
    minrang: "",
    minrangbeware: "",
    minrangout: "",
    model: "",
    mslocationuid: 0,
    msorganisationuid: 0,
    name: "",
    opendoortime: "",
    pmcalinterval: "",
    sn: "",
    tempsetpoint: "",
    temptime: null,
    temptimeAlert: null,
    typeuid: 0,
    uid: 0,
    userangefrom: "",
    userangeto: "",
    calibate:"",
    isactive: "",
    calibate_humid:"",

  });

  const [loadButton, setLoadButton] = useState(false)
  const [isactive, setisactive] = useState('')
  const [formImage, setFormImage] = useState({
    image: "",
    imagestring: "",
    msrefrigeratoruid: id,
    uid: 0,
  })
  const [selectedFileName, setSelectedFileName] = useState('');
  const [loadStatus, setLoadStatus] = useState(false);
  useEffect(() => {
    load();

  }, []);

  const load = async () => {
    setLoadStatus(true)
    let dataImage = await RefrigeratorimgBymsrefrigeratoruid(id);
    let data = await RefrigeratorByUid(id);
    // console.log(data)
    // console.log(data[0])
    if (data && data.length > 0) {
      data[0].isactive != false ? setisactive('Yes') : setisactive('No');
      if(data[0].isactive === null || data[0].isactive === true){
        data[0].isactive = true
      }else{
        data[0].isactive = false
      }
      setForm(data[0]);
      
    }

    if (dataImage && dataImage.length > 0) {
      setFormImage(dataImage[0])
    }
    console.log(dataImage, data);
    setLoadStatus(false)

  };
  const onSave = async () => {
    setLoadButton(true)
    console.log('form',form);
    let res = await putRefrigerator(form);
    // console.log(res);
    // console.log(formImage);
    let resImage = "";
    if (formImage.uid == 0) {
      resImage = await postRefrigeratorimg(formImage)
      console.log(resImage);
    } else {
      resImage = await putRefrigeratorimg(formImage)
      console.log(resImage);
    }

    Swal.fire({
      title: "success",
      timer: 2000,
      icon: 'success'
    })
    setLoadButton(false)


  }


  // const handleFileInput = () => {
  //   Swal.fire({
  //     title: "เพื่อติดตั้งอุปกรณ์ตรวจอุณหภูมิ, แอปนี้จะเข้าถึงและอัปโหลดรูปภาพของคุณ กรุณากด 'ยอมรับ' เพื่ออนุญาตการใช้รูปภาพสำหรับการตั้งค่านี้.",
  //     showCancelButton: true,
  //     confirmButtonText: "ยอมรับ",
  //     cancelButtonText: "ยกเลิก",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       document.getElementById("fileInput").click();
  //     } else {
  //       return;
  //     }
  //   });
  // };
  
  // const readImage = async (file) => {
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = async () => {
  //       console.log(reader.result);
  //       setFormImage({
  //         ...formImage,
  //         imagestring: reader.result.replace(reader.result.split(",", 1)[0] + ",", ""),
  //       });
  //     };
  //     reader.readAsDataURL(file);
  //     setSelectedFileName(file.name);
  //   } else {
  //     // setFormImage({ ...formImage, imagestring: '' });
  //     // setSelectedFileName('');
  //     return
  //   }
  // };

  const handleChange_Isactive = (val) => {
    const isActiveValue = val === 'Yes' ? true : false;
    setForm({ ...form, isactive: isActiveValue });
    setisactive(val);
  }

  return (
    <div className="flex flex-row flex-wrap justify-center w-full p-4 mt-12 mb-10">

      {loadStatus ? <div className="flex justify-center text-center bg-opacity-50">
        <span className="w-full rounded-md">
          <button type="button" className="inline-flex items-center px-4 py-2 text-base font-medium leading-6 transition duration-150 ease-in-out bg-yellow-500 border border-transparent rounded-md cursor-not-allowed active:bg-gray-700" disabled="">
            <svg className="w-5 h-5 mr-3 -ml-1 animate-spin " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Processing
          </button>
        </span>
      </div> :
        <>
          <div className="flex-col w-full p-2 border-r border-gray-400 md:w-1/3">
            <h2 className="text-lg font-bold text-gray-700">
              Equipment information
            </h2>
            <div className="w-full p-2">
              <div className="flex flex-col mb-3 md:flex-row">
                <label className="w-full text-sm md:w-1/2" htmlFor="">
                  SERIAL NUMBER:{" "}
                </label>
                <input
                  type="text"
                  disabled
                  value={form.code}
                  onChange={(e) =>
                    setForm({ ...form, code: e.target.value })
                  }
                  className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                />
              </div>
              <div className="flex flex-col mb-3 md:flex-row">
                <label className="w-full text-sm md:w-1/2" htmlFor="">
                  BRAND:{" "}
                </label>
                <input
                  type="text"
                  value={form.band}
                  onChange={(e) => setForm({ ...form, band: e.target.value })}
                  className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                />
              </div>
              <div className="flex flex-col mb-3 md:flex-row">
                <label className="w-full text-sm md:w-1/2" htmlFor="">
                  MODEL:{" "}
                </label>
                <input
                  type="text"
                  value={form.model}
                  onChange={(e) => setForm({ ...form, model: e.target.value })}
                  className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                />
              </div>
              <div className="flex flex-col mb-3 md:flex-row">
                <label className="w-full text-sm md:w-1/2" htmlFor="">
                  LOCATION NAME:{" "}
                </label>
                <input
                  type="text"
                  value={form.sn}
                  onChange={(e) => setForm({ ...form, sn: e.target.value })}
                  className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                />
              </div>
              <div className="flex flex-col mb-3 md:flex-row">
                <label className="w-full text-sm md:w-1/2" htmlFor="">
                  DEVICE SN:{" "}
                </label>
                <input
                  type="text"
                  value={form.idcode}
                  onChange={(e) => setForm({ ...form, idcode: e.target.value })}
                  className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                />
              </div>
              <div className="flex flex-col mb-3 md:flex-row">
                <label className="w-full text-sm md:w-1/2" htmlFor="">
                  {/* HN No: */}
                  HOSPITAL NO:{" "}
                </label>
                <input
                  type="text"
                  value={form.hnno}
                  onChange={(e) => setForm({ ...form, hnno: e.target.value })}
                  className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                />
              </div>
              <div className="flex flex-col mb-3 md:flex-row" >
                <label className="w-full text-sm md:w-1/2" htmlFor="">
                  ADMIN:{" "}
                </label>
                <input
                  type="text"
                  value={form.typeuid}
                  onChange={(e) => setForm({ ...form, typeuid: e.target.value })}
                  className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                />
              </div>
              <div className="flex flex-col mb-3 md:flex-row" >
                <label className="w-full text-sm md:w-1/2" htmlFor="">
                  TYPE:{" "}
                </label>
                <input
                  type="text"
                  value={form.typeuid}
                  onChange={(e) => setForm({ ...form, typeuid: e.target.value })}
                  className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                />
              </div>

              <div className="flex flex-col mb-3 md:flex-row" >
                <label className="w-full text-sm md:w-1/2" htmlFor="">
                calibate Temperature:{" "}
                </label>
                <input
                  type="text"
                  value={form.calibate}
                  onChange={(e) => setForm({ ...form, calibate: e.target.value })}
                  className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                />
              </div>

              <div className="flex flex-col mb-3 md:flex-row" >
                <label className="w-full text-sm md:w-1/2" htmlFor="">
                calibate Humidity:{" "}
                </label>
                <input
                  type="text"
                  value={form.calibate_humid}
                  onChange={(e) => setForm({ ...form, calibate_humid: e.target.value })}
                  className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                />
              </div>


            </div>

          </div>
          <div className="flex-col w-full p-2 border-r border-gray-400 md:w-1/3">
            <h2 className="text-lg font-bold text-gray-700">Parameter</h2>
            <div className="w-full p-2">
              <div className="flex flex-col mb-3 md:flex-row">
                <label className="w-full text-sm md:w-2/3" htmlFor="">
                  Temp Set Point (C):{" "}
                </label>
                <input
                  type="text"
                  value={form.tempsetpoint}
                  onChange={(e) =>
                    setForm({ ...form, tempsetpoint: e.target.value })
                  }
                  className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                />
              </div>
              <div className="flex flex-col mb-3 md:flex-row">
                <label className="w-full text-sm md:w-2/3" htmlFor="">
                  Accuracy (C):{" "}
                </label>
                <input
                  type="text"
                  value={form.accuracy}
                  onChange={(e) => setForm({ ...form, accuracy: e.target.value })}
                  className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                />
              </div>
              <div className="flex flex-col mb-3 md:flex-row">
                <label className="w-full text-sm md:w-2/3 " htmlFor="">
                  Use Range:{" "}
                </label>
                <div className="flex flex-row ">
                  <input
                    type="text"
                    value={form.userangefrom}
                    onChange={(e) =>
                      setForm({ ...form, userangefrom: e.target.value })
                    }
                    className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                  />
                  <input
                    type="text"
                    value={form.userangeto}
                    onChange={(e) =>
                      setForm({ ...form, userangeto: e.target.value })
                    }
                    className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                  />
                </div>
              </div>
              <div className="flex flex-col mb-3 md:flex-row">
                <label className="w-full text-sm md:w-2/3" htmlFor="">
                  High Temp Alarm (C):{" "}
                </label>
                <input
                  type="text"
                  value={form.maxrang}
                  onChange={(e) => setForm({ ...form, maxrang: e.target.value })}
                  className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                />
              </div>
              <div className="flex flex-col mb-3 md:flex-row">
                <label className="w-full text-sm md:w-2/3" htmlFor="">
                  Low Temp Alarm (C):{" "}
                </label>
                <input
                  type="text"
                  value={form.minrang}
                  onChange={(e) => setForm({ ...form, minrang: e.target.value })}
                  className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                />
              </div>
              <div className="flex flex-col mb-3 md:flex-row">
                <label className="w-full text-sm md:w-2/3" htmlFor="">
                  Open Door Time (min):{" "}
                </label>
                <input
                  type="text"
                  value={form.opendoortime}
                  onChange={(e) =>
                    setForm({ ...form, opendoortime: e.target.value })
                  }
                  className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                />
              </div>
              <div className="flex flex-col mb-3 md:flex-row">
                <label className="w-full text-sm md:w-2/3" htmlFor="">
                  IPM/CAL interval(month):{" "}
                </label>
                <input
                  type="text"
                  value={form.pmcalinterval}
                  onChange={(e) =>
                    setForm({ ...form, pmcalinterval: e.target.value })
                  }
                  className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                />
              </div>
              <div className="flex flex-col mb-3 md:flex-row">
                <label className="w-full text-sm md:w-2/3" htmlFor="">
                  Min humidity:{" "}
                </label>
                <input
                  type="text"
                  value={form.minhumid}
                  onChange={(e) =>
                    setForm({ ...form, minhumid: e.target.value })
                  }
                  className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                />
              </div>
              <div className="flex flex-col mb-3 md:flex-row">
                <label className="w-full text-sm md:w-2/3" htmlFor="">
                  Max humidity:{" "}
                </label>
                <input
                  type="text"
                  value={form.maxhumid}
                  onChange={(e) =>
                    setForm({ ...form, maxhumid: e.target.value })
                  }
                  className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                />
              </div>
              <div className="flex flex-col mb-3 md:flex-row">
                <label className="w-full text-sm md:w-2/3" htmlFor="">
                Active:{" "}
                </label>
                <select 
                  value={isactive} 
                  onChange={(e) => 
                    handleChange_Isactive(e.target.value)
                  }
                  className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
              </div>

            </div>
          </div>
          {/* <div className="flex-col w-full p-2 border-r border-gray-400 md:w-1/3">
            <h2 className="text-lg font-bold text-gray-700">Refrigerator Image</h2>
            <div className="w-full p-2">
            <div className="flex flex-col mb-3 md:flex-row">
              <label className="w-full text-sm md:w-1/5">
                File input
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => readImage(e.target.files[0])}
                className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg"
                id="fileInput"
                style={{ display: "none" }}
              />
              <button
                type="button"
                onClick={handleFileInput}
                className="w-full px-4 py-2 ml-2 bg-blue-200 rounded-lg overflow-x-auto"
              >
                {selectedFileName ? selectedFileName : 'Choose File'}
              </button>
            </div>

              <div className="w-full">
                <img src={`data:image/jpeg;base64,${formImage.imagestring}`} alt="" />
              </div>
            </div>
          </div> */}

          <div className="flex flex-row flex-wrap justify-center w-full p-4 mt-12 mb-10">


            <div className="flex-col w-full p-2 md:w-1/3">
              {!loadButton ? <button
                onClick={() => onSave()}
                className="w-full p-2 text-sm font-bold text-white rounded-lg bg-luka-light ">
                Save
              </button> : <div>
                <div className="flex justify-around">
                  <span className="inline-flex rounded-md shadow-sm">
                    <button type="button" className="inline-flex items-center px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-red-600 border border-transparent rounded-md cursor-not-allowed hover:bg-red-500 focus:border-red-700 active:bg-red-700" disabled="">
                      <svg className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Processing
                    </button>
                  </span>
                </div>
              </div>}

            </div>
          </div>

        </>
      }
    </div>
  );
};

export default EditRefrigerator;
