import React, { useState, useEffect } from "react";
import {
  RefrigeratorimgBymsrefrigeratoruid,
  RefrigeratorByUid,
  LocationByuid,
  postRefrigeratorimg
} from "../../store/api/api";
import { Link, useParams, useLocation  } from "react-router-dom";
import Swal from 'sweetalert2';

const Refrigerator = () => {
  let { id } = useParams();
  // const location = useLocation();
  const [load, setLoad] = useState(false);
  const [editData, setEditData] = useState(true);
  const [macid, setMacid] = useState("");
  const [RefrigeratorImage, setRefrigeratorImage] = useState([]);
  console.log(RefrigeratorImage,'imagestring');
  const [RefrigeratorData, setRefrigeratorData] = useState({
    name: "",
    hnno: "",
    band: "",
    model: "",
    sn: "",
    macaddress: "",
    code:"",
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () =>{
    setLoad(true)
      let dataImage = await RefrigeratorimgBymsrefrigeratoruid(id);
      setRefrigeratorImage(dataImage[dataImage.length - 1]);
      let data = await RefrigeratorByUid(id);
      
      if (data[0]) {
        let mslocationData = await  LocationByuid(data[0].mslocationuid)
        console.log('mslocationData',mslocationData);
        setRefrigeratorData({
          name: data[0].name?data[0].name:"",
          hnno: data[0].hnno?data[0].hnno:"",
          band: data[0].band?data[0].band:"",
          model: data[0].model?data[0].model:"",
          sn: data[0].sn?data[0].sn:"",
          macaddress: data[0].macaddress?data[0].macaddress:"",
          code:data[0].code?data[0].code:"",
          mslocationuid:data[0].code?data[0].mslocationuid:"",
          mslocationName:mslocationData[0].name?mslocationData[0].name:"",

        });
        setMacid(data[0].code)
      }
      
     setTimeout(() => {
      setLoad(false)
     }, 500);

    }


    const handleFileInput = () => {
      Swal.fire({
          title: "เพื่อติดตั้งอุปกรณ์ตรวจอุณหภูมิ, แอปนี้จะเข้าถึงและอัปโหลดรูปภาพของคุณ กรุณากด 'ยอมรับ' เพื่ออนุญาตการใช้รูปภาพสำหรับการตั้งค่านี้.",
          showCancelButton: true,
          confirmButtonText: "ยอมรับ",
          cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
          if (result.isConfirmed) {
              const input = document.createElement('input');
              input.type = 'file';
              input.accept = 'image/*';
              input.addEventListener('change', async (event) => {
                  const file = event.target.files[0];
                  if (file) {
                      const reader = new FileReader();
                      reader.onload = (e) => {
                          const pictureDataURL = e.target.result;
                          Swal.fire({
                              title: "คุณต้องการบันทึกรูปภาพหรือไม่",
                              imageUrl: pictureDataURL,
                              showCancelButton: true,
                              confirmButtonText: "ยอมรับ",
                              cancelButtonText: "ยกเลิก",
                          }).then(async (result) => {
                              if (result.isConfirmed) {
                                  const formImage = reader.result.replace(reader.result.split(",", 1)[0] + ",", "");
                                  const formvalImage = {
                                      image: "",
                                      imagestring: formImage,
                                      msrefrigeratoruid: id,
                                  };
                                  console.log(formvalImage);
                                  let resImage = await postRefrigeratorimg(formvalImage)
                                  loadData();
                              } else {
                                  Swal.close();
                              }
                          });
                      };
                      reader.readAsDataURL(file);
                  }
              });
              input.click();
          } else {
              return;
          }
      });
  };
  
  

  
  return (
    <div className="flex flex-row flex-wrap justify-center w-full h-full p-4 mt-12">

{load? <div className="flex justify-center bg-opacity-50 ">
    <span className="w-full rounded-md">
      <button type="button" className="inline-flex items-center px-4 py-2 text-base font-medium leading-6 transition duration-150 ease-in-out bg-yellow-500 border border-transparent rounded-md cursor-not-allowed active:bg-gray-700" disabled="">
        <svg className="w-5 h-5 mr-3 -ml-1 animate-spin " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        Processing
      </button>
    </span>
  </div>:
  
  <>
<Link to={`/EditRefrigerator/${id}`} className="flex justify-end w-full pr-4 text-luka-dark">
 
 <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>

 </Link>
 
     <div className="flex items-center justify-center w-full md:w-1/3 md:h-3/4 md:left-4">
       <img
         onClick={handleFileInput}
         src={RefrigeratorImage?.imagestring === ''  ? '/Images/Add_Image.png' : `data:image/png;base64,${RefrigeratorImage?.imagestring}`}
         className="object-cover h-full w-full md:w-full md:h-full"
         alt=''
        //  style={{ width: '500px', height: '500px' }}
       />
     </div>


 <div className="w-full p-4 ml-auto md:w-2/3">
   <div className="flex flex-col">
   <div className="flex flex-col w-full p-2 mb-2">
       <label className="font-extrabold text-gray-500" htmlFor="name">
         Macid
       </label>
       <input
         type="text"
         name="name"
         id=""
         value={RefrigeratorData.code}
         onChange={(e) =>
           setRefrigeratorData({
             ...RefrigeratorData,
             code: e.target.value,
           })
         }
         disabled={editData}
         className="px-4 py-2 font-bold rounded-lg bg-blue-50 text-luka-dark"
       />
     </div>
     

     <div className="flex flex-col w-full p-2 mb-2">
       <label className="font-extrabold text-gray-500" htmlFor="name">
        NAME
       </label>
       <input
         type="text"
         name="name"
         id=""
         value={RefrigeratorData.mslocationName}
         onChange={(e) =>
           setRefrigeratorData({
             ...RefrigeratorData,
             name: e.target.value,
           })
         }
         disabled={editData}
         className="px-4 py-2 font-bold rounded-lg bg-blue-50 text-luka-dark"
       />
     </div>


     <div className="flex flex-col w-full p-2 mb-2">
       <label className="font-extrabold text-gray-500" htmlFor="name">
       DEVICE NUMBER
       </label>
       <input
         type="text"
         name="name"
         id=""
         value={RefrigeratorData.name}
         onChange={(e) =>
           setRefrigeratorData({
             ...RefrigeratorData,
             name: e.target.value,
           })
         }
         disabled={editData}
         className="px-4 py-2 font-bold rounded-lg bg-blue-50 text-luka-dark"
       />
     </div>

     <div className="flex flex-col w-full p-2 mb-2">
       <label className="font-extrabold text-gray-500" htmlFor="HN">
   HOSPITAL NO
       </label>
       <input
         type="text"
         name="HN"
         id=""
         value={RefrigeratorData.hnno}
         onChange={(e) =>
           setRefrigeratorData({
             ...RefrigeratorData,
             hnno: e.target.value,
           })
         }
         disabled={editData}
         className="px-4 py-2 font-bold rounded-lg bg-blue-50 text-luka-dark"
       />
     </div>

     <div className="flex flex-col w-full p-2 mb-2">
       <label className="font-extrabold text-gray-500" htmlFor="name">
         BRAND
       </label>
       <input
         type="text"
         name="BRAND"
         id=""
         value={RefrigeratorData.band}
         onChange={(e) =>
           setRefrigeratorData({
             ...RefrigeratorData,
             band: e.target.value,
           })
         }
         disabled={editData}
         className="px-4 py-2 font-bold rounded-lg bg-blue-50 text-luka-dark"
       />
     </div>

     <div className="flex flex-col w-full p-2 mb-2">
       <label className="font-extrabold text-gray-500" htmlFor="MODEL">
         MODEL
       </label>
       <input
         type="text"
         name="MODEL"
         id=""
         value={RefrigeratorData.model}
         onChange={(e) =>
           setRefrigeratorData({
             ...RefrigeratorData,
             model: e.target.value,
           })
         }
         disabled={editData}
         className="px-4 py-2 font-bold rounded-lg bg-blue-50 text-luka-dark"
       />
     </div>

 


   </div>
 </div>

<div className="w-full p-4 ml-auto md:w-2/3">
<div className="flex flex-col w-full p-2 mb-2">
       <label className="font-extrabold text-gray-500" htmlFor="SN">
        LOCATION NAME
       </label>
       <input
         type="text"
         name="SN"
         id=""
         value={RefrigeratorData.sn}
         onChange={(e) =>
           setRefrigeratorData({ ...RefrigeratorData, sn: e.target.value })
         }
         disabled={editData}
         className="px-4 py-2 font-bold rounded-lg bg-blue-50 text-luka-dark"
       />
     </div>

     <div className="flex flex-col w-full p-2 mb-2">
       <label
         className="font-extrabold text-gray-500"
         htmlFor="MACADDRESS"
       >
         MAC ADDRESS
       </label>
       <input
         type="text"
         name="MACADDRESS"
         id=""
         value={RefrigeratorData.macaddress}
         onChange={(e) =>
           setRefrigeratorData({
             ...RefrigeratorData,
             macaddress: e.target.value,
           })
         }
         disabled={editData}
         className="px-4 py-2 font-bold rounded-lg bg-blue-50 text-luka-dark"
       />
     </div>


     <div className="flex flex-row flex-wrap justify-around w-full p-2 mb-2 ">
<Link className="w-1/2 p-2 mb-2 md:w-1/2 md:w-1/5" to={`/TemperatureMonitoring/${macid}`}> <button className="w-full p-2 font-bold text-white rounded-lg bg-luka text-md ">Temperature</button></Link>
<Link className="w-1/2 p-2 mb-2 md:w-1/2 md:w-1/5" to={`/AlarmList/${id}`}> <button className="w-full p-2 font-bold text-white rounded-lg bg-luka text-md ">Alarm List</button></Link>
<Link className="w-1/3 p-2 mb-2 md:w-1/3 md:w-1/5"  to={`/report/hour/${macid}`}> <button className="w-full p-2 font-bold text-white bg-green-600 rounded-lg text-md ">Hourly Report</button></Link>
<Link className="w-1/3 p-2 mb-2 md:w-1/3 md:w-1/5" to={`/report/today/${macid}`}> <button className="w-full p-2 font-bold text-white bg-green-600 rounded-lg text-md ">Daily Report</button></Link>
<Link className="w-1/3 p-2 mb-2 md:w-1/3 md:w-1/5" to={`/report/week/${macid}`}> <button className="w-full p-2 font-bold text-white bg-green-600 rounded-lg text-md ">Weekly Report</button></Link>
<Link className="w-1/3 p-2 mb-2 md:w-1/3 md:w-1/5"  to={`/report/month/${macid}`}> <button className="w-full p-2 font-bold text-white bg-green-600 rounded-lg text-md ">Monthly Report</button></Link>
     </div>

</div>
  </>
  
  }
</div>


  );
};

export default Refrigerator;
