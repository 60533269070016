import React, { useState, useEffect, Fragment,  } from "react";
import {
  //  Link,
  useHistory,
} from "react-router-dom";
import { login,  DataByToken } from "../store/api/api";
import { useDispatch } from "react-redux";
import { userAdded ,userClear} from "../store/usersSlice";
import { temperatureClear } from "../store/temperature";
// import { useParams } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react'
const Login = () => {
  // let { id } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('token');
  const [status, setStatus] = useState(false)
  let history = useHistory();
  let dispatch = useDispatch();
  useEffect(() => {
    
  
    async function loadUser() {
      await dispatch(temperatureClear());
      await dispatch(userClear());

      
      localStorage.removeItem("access");
      localStorage.removeItem("accessData");
      
      if (myParam) {
        // console.log(myParam);
        let data = await DataByToken(myParam);
        // console.log("token", data);
        if (data && data.length > 0) {
          await localStorage.setItem("accessData", myParam);
          await localStorage.setItem("access", JSON.stringify(data));
          await dispatch(userAdded(data));
         history.push("/home");
        }
        else{
          alert("token")
        }
      }
    }
    loadUser();
  }, [dispatch, history, myParam]);

  const [user, setUser] = useState({
    username: "",
    password: "",
  });

  const onLogin = async () => {
    let data = await login(user);
     console.log(data);
    if (data.access_Token) {
      // detete
      localStorage.removeItem("access");
      localStorage.removeItem("accessData");

      let token = await DataByToken(data.access_Token);
      // console.log('DataByToken' , token);
      // console.log(data.access_Token);
      // console.log(token);
      if (token.length > 0) {
        // set
        await localStorage.setItem("accessData", data.access_Token);
        await localStorage.setItem("access", JSON.stringify(token));

        await dispatch(userAdded(token[0]));

        // let RefrigeratorData = await Refrigerator();
        // RefrigeratorData = RefrigeratorData.sort((a,b)=>b.uid-a.uid)
        // dispatch(temperatureAdded(RefrigeratorData));
        history.push("/home");
      }
    }else{
      setStatus(true)
    }
  };


  function closeModal() {
    setStatus(false)
  }

  function openModal() {
    setStatus(true)
  }


  return (
    <div className="flex justify-center w-screen h-screen bg-blue-900 ">
      <div className="absolute duration-300 h-1/6 top-5 sm:top-10">
        <div className="relative z-20 bg-white rounded-full shadow-lg ">
          <img src="/LUKA_Technology.png" className="w-40 h-40 " alt="" />
        </div>
      </div>
      <div className="absolute bottom-0 w-full bg-white h-5/6 rounded-t-3xl">
        <div className="flex flex-col items-center w-full h-full pt-24">
          <div className="mb-3 text-center">
            <h1 className="text-2xl font-bold text-luka-light">
              Login to Start your
            </h1>
            <h1 className="text-2xl font-bold text-luka-light">Application</h1>
          </div>
          <div className="flex flex-col w-10/12 sm:w-1/3">
            <div className="flex flex-row items-center justify-center">
              <div className="mr-2 text-luka-light">
                <svg
              className={`w-6 h-6  ${!status?'text-luka-light':'text-red-800'}`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  ></path>
                </svg>
              </div>
              <div className="relative z-0 w-full mb-5 ">
                <input
                  onChange={(e) =>
                    setUser({ ...user, username: e.target.value })
                  }
                  type="text"
                  name="name"
                  placeholder=" "
                  required
                className={`pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-luka-dark 
                  ${!status?'border-luka-light':'border-red-800'}`}   />
                <label
                  htmlFor="name"
                  className="absolute text-sm text-gray-400 duration-300 top-3 -z-10 origin-0"
                >
                  username
                </label>
                <span className="hidden text-sm text-red-600" id="error">
                  Name is required
                </span>
              </div>
            </div>

            <div className="flex flex-row items-center justify-center">
              <div className="mr-2 text-luka-light">
                <svg
                  className={`w-6 h-6  ${!status?'text-luka-light':'text-red-800'}`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                  ></path>
                </svg>
              </div>
              <div className="relative z-0 w-full mb-5">
                <input
                  onChange={(e) =>
                    setUser({ ...user, password: e.target.value })
                  }
                  type="password"
                  name="name"
                  placeholder=" "
                  required
                  className={`pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-luka-dark 
                  ${!status?'border-luka-light':'border-red-800'}`}
                />
                <label
                  htmlFor="name"
                  className="absolute text-sm text-gray-400 duration-300 top-3 -z-10 origin-0"
                >
                  password{" "}
                </label>
                <span className="hidden text-sm text-red-600" id="error">
                  Name is required
                </span>
              </div>
            </div>
            <div className="flex-row items-center justify-between hidden mb-10 ">
              <div className="">
                <label className="inline-flex items-center mt-3">
                  <span className="ml-2 text-sm text-gray-700">
                    Forgot Password
                  </span>
                </label>
              </div>
              <label className="inline-flex items-center mt-3">
                <input
                  type="checkbox"
                  className="w-5 h-5 text-green-600 form-checkbox"
                />
                <span className="ml-2 text-sm text-gray-700"> Remember Me</span>
              </label>
            </div>

            <div className="flex items-center justify-center w-full">
              <button
                onClick={() => onLogin()}
                className="w-full px-4 py-2 text-white rounded-lg bg-luka-dark"
              >
                Sing in
              </button>
            </div>
          </div>
          <div className="flex items-center justify-center h-full">
            <p className="text-sm text-luka-light ">Version 1.0.2</p>
          </div>
        </div>
      </div>

      <Transition appear show={status} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-red-100 shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                 Error
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                  User or Password not found
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                    onClick={closeModal}
                  >
                    OK
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Login;
