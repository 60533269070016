import React from 'react'

const ServiceSetupCompany = () => {
    return (
        <div className=" p-4">
        <div className="flex flex-col flex-wrap p-4 rounded-lg justify-center items-center">
        
        <div className="w-60 h-60 bg-white flex flex-col justify-center items-center p-4 rounded-lg">

          <div className="flex flex-col  text-center mb-4">
              <h1 className="w-full mb-5 text-luka-light font-bold text-xl">กรุณาระบุบ Password</h1>
              <input type="text" className=" px-4 py-2 border border-luka-light rounded-lg"/>
          </div>
          <div className="w-full flex justify-end">
              <button className="bg-luka-dark text-white rounded-lg px-4 py-1">Submit</button>
          </div>
        </div>
         </div>
      </div>
  
    )
}

export default ServiceSetupCompany
