import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { CSVLink } from "react-csv";

import {
  reportDaily,
  RefrigeratorByMsOrganizationUid,
  OrganisationByuid,
  Refrigerator,
  NewLocationByuid,
  getLocation,
  reportDaily_Humi,
} from "../../store/api/api";
import moment from "moment";
import Chart from "react-apexcharts";
import DatePicker from "react-datepicker";

const Daily = () => {
  let user = localStorage.getItem("access");
  try {
    user = JSON.parse(user);
  } catch (error) {
    console.log(error);
  }
  const moment = require('moment');
  require('moment/locale/th');
  moment.updateLocale('th', {
    longDateFormat: {
      L: "D MMMM YYYY",
      LT: "D MMMM YYYY HH:mm:ss",
    },
  });
  const [csvData, setCsvData] = useState([]);
  const [refrigerator, setRefrigerator] = useState([]);
  const Tabs = ['Temperature', 'Humidity']
  const [tab, setTab] = useState(0)
  const currentDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm");
  const momentDateTime = moment(currentDateTime, "YYYY-MM-DD HH:mm");
  momentDateTime.add(543, 'years');
  const formattedDate = momentDateTime.locale('th').format("D MMMM YYYY");
  const formattedTime = momentDateTime.format("HH:mm");
  const [loadStatus, setLoadStatus] = useState(false);
  const [data, setData] = useState({});
  const [chartData, setChartData] = useState({
    series: [{
      name: "Temperature",
      data: []
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      colors: ['#004481'],
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: 'ข้อมูล ณ วันที่ ' + formattedDate + ' เวลา ' + formattedTime + ' น.',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: [],
      }
    },


  });
  const [formData, setFormData] = useState({
    macid: 0,
    date: new Date(),
    HourFrom:
      ((new Date().getHours() - 1).toString().length == 1
        ? "0" + (new Date().getHours() - 1).toString()
        : (new Date().getHours() - 1).toString()) + ":00",
    HourTo:
      (new Date().getHours().toString().length == 1
        ? "0" + new Date().getHours().toString()
        : new Date().getHours().toString()) + ":00",
    orgUID: Number(user[0]?.msorganisationuid),
  });

  let { id } = useParams();

  useEffect(() => {
    const load = async () => {
      setLoadStatus(true)
      let data__ = await loadRefrigerator();
      // console.log('data__',data__)
      if (id == 0) {
        setLoadStatus(false)
      } else {
        // let dataFindbyUid =await data__.find((d) => d.uid == id);
        if (tab === 0) {
          setFormData({ ...formData, macid: id });
          let formPost = {
            macid: 0,
            date: moment(new Date()).format("YYYY-MM-DD"),
            HourFrom:
              ((new Date().getHours() - 1).toString().length == 1
                ? "0" + (new Date().getHours() - 1).toString()
                : (new Date().getHours() - 1).toString()) + ":00",
            HourTo:
              (new Date().getHours().toString().length == 1
                ? "0" + new Date().getHours().toString()
                : new Date().getHours().toString()) + ":00",
            orgUID: Number(user[0]?.msorganisationuid),
            macid: id,
          };
          let res = await reportDaily(formPost);
          let data__ = [];
          let lableChart = [];
          let valueChart = [];
          for (const iterator of res) {
            data__.push({ x: iterator.time, y: iterator.ftempin });
            lableChart.push(iterator.time.slice(10).slice(0, 6))
            valueChart.push(iterator.ftempin)
          }

          setData({
            datasets: [
              {
                label:
                  "รายงานค่าเฉลี่ยของอุณหภูมิในแต่ละช่วงเวลาของวัน ของตู้แช่",
                data: data__,
                fill: false,
                backgroundColor: "#19578d",
                borderColor: "#19578d",
              },
            ],
          });
          const datetimeString = data__.map((item) => ({
            Date: item.x.split(' ')[0], 
            Time: item.x.split(' ')[1].split(':').slice(0, 2).join(':'), 
            Temperature: item.y 
          }));
          setCsvData([...datetimeString]);
          setChartData({ ...chartData, series: [{ ...chartData.series, data: data__ }], options: { ...chartData.options, xaxis: { categories: lableChart } }, })

          setLoadStatus(false)
        } else {
          setFormData({ ...formData, macid: id });
          let formPost = {
            macid: 0,
            date: moment(new Date()).format("YYYY-MM-DD"),
            HourFrom:
              ((new Date().getHours() - 1).toString().length == 1
                ? "0" + (new Date().getHours() - 1).toString()
                : (new Date().getHours() - 1).toString()) + ":00",
            HourTo:
              (new Date().getHours().toString().length == 1
                ? "0" + new Date().getHours().toString()
                : new Date().getHours().toString()) + ":00",
            orgUID: Number(user[0]?.msorganisationuid),
            macid: id,
          };
          let res = await reportDaily_Humi(formPost);
          // console.log(res);
          let data__ = [];
          let lableChart = [];
          let valueChart = [];
          for (const iterator of res) {
            data__.push({ x: iterator.time, y: iterator.fhumi });
            lableChart.push(iterator.time.slice(10).slice(0, 6))
            valueChart.push(iterator.fhumi)
          }

          setData({
            datasets: [
              {
                label:
                  "รายงานค่าเฉลี่ยของอุณหภูมิในแต่ละช่วงเวลาของวัน ของตู้แช่",
                data: data__,
                fill: false,
                backgroundColor: "#19578d",
                borderColor: "#19578d",
              },
            ],
          });
          const datetimeString = data__.map((item) => ({
            Date: item.x.split(' ')[0], 
            Time: item.x.split(' ')[1].split(':').slice(0, 2).join(':'), 
            Humidity: item.y
          }));
          setCsvData([...datetimeString]);
          setChartData({ ...chartData, series: [{ ...chartData.series, data: data__ }], options: { ...chartData.options, xaxis: { categories: lableChart } }, })

          setLoadStatus(false)
        }

      }
    };
    load();
  }, []);

  const loadDataTemperature = async (macid) => {
    setLoadStatus(true)
    setFormData({ ...formData, macid: macid });
    let param = {
      macid: macid,
      date: moment(formData.date).format("YYYY-MM-DD"),
      HourFrom: formData.HourFrom,
      HourTo: formData.HourTo,
      orgUID: formData.orgUID,
    };

    let res = await reportDaily(param);
    console.log(res)
    let data__ = [];
    for (const iterator of res) {
      data__.push({ x: moment(formData.date).format("YYYY-MM-DD") + iterator.time.slice(10).slice(0, 6), y: iterator.ftempin });
    }
    setData({
      datasets: [
        {
          label: "รายงานค่าเฉลี่ยของอุณหภูมิในแต่ละช่วงเวลาของวัน ของตู้แช่",
          data: data__,
          fill: false,
          backgroundColor: "#19578d",
          borderColor: "#19578d",
        },
      ],
    });
    const datetimeString = data__.map((item) => {
      const dateParts = item.x.split(' ')[0].split('-');
      const day = dateParts[2];
      const month = dateParts[1];
      const year = dateParts[0];
    
      return {
        Date: `${day}/${month}/${year}`,
        Time: item.x.split(' ')[1].split(':').slice(0, 2).join(':'),
        Temperature: item.y
      };
    });
    
    setCsvData([...datetimeString]);
    setChartData({
      ...chartData,
      series: [{ ...chartData.series, data: data__ }],
    });
    setLoadStatus(false)
  }

  const loadDataHumidity = async (macid) => {
    setLoadStatus(true)
    setFormData({ ...formData, macid: macid });
    let param = {
      macid: macid,
      date: moment(formData.date).format("YYYY-MM-DD"),
      HourFrom: formData.HourFrom,
      HourTo: formData.HourTo,
      orgUID: formData.orgUID,
    };

    let res = await reportDaily_Humi(param);
    let data__ = [];
    for (const iterator of res) {
      data__.push({ x: moment(formData.date).format("YYYY-MM-DD") + iterator.time.slice(10).slice(0, 6), y: iterator.fhumi });
    }
    setData({
      datasets: [
        {
          label: "รายงานค่าเฉลี่ยของอุณหภูมิในแต่ละช่วงเวลาของวัน ของตู้แช่",
          data: data__,
          fill: false,
          backgroundColor: "#19578d",
          borderColor: "#19578d",
        },
      ],
    });
    const datetimeString = data__.map((item) => {
      const dateParts = item.x.split(' ')[0].split('-');
      const day = dateParts[2];
      const month = dateParts[1];
      const year = dateParts[0];
    
      return {
        Date: `${day}/${month}/${year}`,
        Time: item.x.split(' ')[1].split(':').slice(0, 2).join(':'),
        Humidity: item.y
      };
    });
    
    setCsvData([...datetimeString]);
    setChartData({
      ...chartData,
      series: [{ ...chartData.series, data: data__ }],
    });
    setLoadStatus(false)
  }

  const loadData = async () => {
    if (tab === 0) {
      setLoadStatus(true)
      let param = {
        macid: formData.macid,
        date: moment(formData.date).format("YYYY-MM-DD"),
        HourFrom: formData.HourFrom,
        HourTo: formData.HourTo,
        orgUID: formData.orgUID,
      };

      let res = await reportDaily(param);
      let data__ = [];
      for (const iterator of res) {
        data__.push({ x: moment(formData.date).format("YYYY-MM-DD") + iterator.time.slice(10).slice(0, 6), y: iterator.ftempin });
      }
      setData({
        datasets: [
          {
            label: "รายงานค่าเฉลี่ยของอุณหภูมิในแต่ละช่วงเวลาของวัน ของตู้แช่",
            data: data__,
            fill: false,
            backgroundColor: "#19578d",
            borderColor: "#19578d",
          },
        ],
      });
      const datetimeString = data__.map((item) => {
        const dateParts = item.x.split(' ')[0].split('-');
        const day = dateParts[2];
        const month = dateParts[1];
        const year = dateParts[0];
      
        return {
          Date: `${day}/${month}/${year}`,
          Time: item.x.split(' ')[1].split(':').slice(0, 2).join(':'),
          Temperature: item.y
        };
      });
      
      setCsvData([...datetimeString]);
      setChartData({
        ...chartData,
        series: [{ ...chartData.series, data: data__ }],
      });
      setLoadStatus(false)
    } else {
      setLoadStatus(true)
      let param = {
        macid: formData.macid,
        date: moment(formData.date).format("YYYY-MM-DD"),
        HourFrom: formData.HourFrom,
        HourTo: formData.HourTo,
        orgUID: formData.orgUID,
      };

      let res = await reportDaily_Humi(param);
      let data__ = [];
      for (const iterator of res) {
        data__.push({ x: moment(formData.date).format("YYYY-MM-DD") + iterator.time.slice(10).slice(0, 6), y: iterator.fhumi });
      }
      setData({
        datasets: [
          {
            label: "รายงานค่าเฉลี่ยของอุณหภูมิในแต่ละช่วงเวลาของวัน ของตู้แช่",
            data: data__,
            fill: false,
            backgroundColor: "#19578d",
            borderColor: "#19578d",
          },
        ],
      });
      const datetimeString = data__.map((item) => {
        const dateParts = item.x.split(' ')[0].split('-');
        const day = dateParts[2];
        const month = dateParts[1];
        const year = dateParts[0];
      
        return {
          Date: `${day}/${month}/${year}`,
          Time: item.x.split(' ')[1].split(':').slice(0, 2).join(':'),
          Humidity: item.y
        };
      });
      
      setCsvData([...datetimeString]);
      setChartData({
        ...chartData,
        series: [{ ...chartData.series, data: data__ }],
      });
      setLoadStatus(false)
    }

  };

  const loadData_Newdate = async (date) => {
    if (tab === 0) {
      setLoadStatus(true)
      let param = {
        macid: formData.macid,
        date: moment(date).format("YYYY-MM-DD"),
        HourFrom: formData.HourFrom,
        HourTo: formData.HourTo,
        orgUID: formData.orgUID,
      };

      let res = await reportDaily(param);
      let data__ = [];
      for (const iterator of res) {
        data__.push({ x: moment(date).format("YYYY-MM-DD") + iterator.time.slice(10).slice(0, 6), y: iterator.ftempin });
      }
      setData({
        datasets: [
          {
            label: "รายงานค่าเฉลี่ยของอุณหภูมิในแต่ละช่วงเวลาของวัน ของตู้แช่",
            data: data__,
            fill: false,
            backgroundColor: "#19578d",
            borderColor: "#19578d",
          },
        ],
      });
      const datetimeString = data__.map((item) => {
        const dateParts = item.x.split(' ')[0].split('-');
        const day = dateParts[2];
        const month = dateParts[1];
        const year = dateParts[0];
      
        return {
          Date: `${day}/${month}/${year}`,
          Time: item.x.split(' ')[1].split(':').slice(0, 2).join(':'),
          Temperature: item.y
        };
      });
      
      setCsvData([...datetimeString]);
      setChartData({
        ...chartData,
        series: [{ ...chartData.series, data: data__ }],
      });
      setLoadStatus(false)
    } else {
      setLoadStatus(true)
      let param = {
        macid: formData.macid,
        date: moment(date).format("YYYY-MM-DD"),
        HourFrom: formData.HourFrom,
        HourTo: formData.HourTo,
        orgUID: formData.orgUID,
      };

      let res = await reportDaily_Humi(param);
      let data__ = [];
      for (const iterator of res) {
        data__.push({ x: moment(date).format("YYYY-MM-DD") + iterator.time.slice(10).slice(0, 6), y: iterator.fhumi });
      }
      setData({
        datasets: [
          {
            label: "รายงานค่าเฉลี่ยของอุณหภูมิในแต่ละช่วงเวลาของวัน ของตู้แช่",
            data: data__,
            fill: false,
            backgroundColor: "#19578d",
            borderColor: "#19578d",
          },
        ],
      });
      const datetimeString = data__.map((item) => {
        const dateParts = item.x.split(' ')[0].split('-');
        const day = dateParts[2];
        const month = dateParts[1];
        const year = dateParts[0];
      
        return {
          Date: `${day}/${month}/${year}`,
          Time: item.x.split(' ')[1].split(':').slice(0, 2).join(':'),
          Humidity: item.y
        };
      });
      
      setCsvData([...datetimeString]);
      setChartData({
        ...chartData,
        series: [{ ...chartData.series, data: data__ }],
      });
      setLoadStatus(false)
    }

  };

  const loadData_NewTime = async (value, hour) => {
    if (tab === 0) {
      setLoadStatus(true)
      let param = {
        macid: formData.macid,
        date: moment(formData.date).format("YYYY-MM-DD"),
        HourFrom: value,
        HourTo: hour,
        orgUID: formData.orgUID,
      };

      let res = await reportDaily(param);
      let data__ = [];
      for (const iterator of res) {
        data__.push({ x: moment(formData.date).format("YYYY-MM-DD") + iterator.time.slice(10).slice(0, 6), y: iterator.ftempin });
      }
      setData({
        datasets: [
          {
            label: "รายงานค่าเฉลี่ยของอุณหภูมิในแต่ละช่วงเวลาของวัน ของตู้แช่",
            data: data__,
            fill: false,
            backgroundColor: "#19578d",
            borderColor: "#19578d",
          },
        ],
      });
      const datetimeString = data__.map((item) => {
        const dateParts = item.x.split(' ')[0].split('-');
        const day = dateParts[2];
        const month = dateParts[1];
        const year = dateParts[0];
      
        return {
          Date: `${day}/${month}/${year}`,
          Time: item.x.split(' ')[1].split(':').slice(0, 2).join(':'),
          Temperature: item.y
        };
      });
      
      setCsvData([...datetimeString]);
      setChartData({
        ...chartData,
        series: [{ ...chartData.series, data: data__ }],
      });
      setLoadStatus(false)
    } else {
      setLoadStatus(true)
      let param = {
        macid: formData.macid,
        date: moment(formData.date).format("YYYY-MM-DD"),
        HourFrom: value,
        HourTo: hour,
        orgUID: formData.orgUID,
      };

      let res = await reportDaily_Humi(param);
      let data__ = [];
      for (const iterator of res) {
        data__.push({ x: moment(formData.date).format("YYYY-MM-DD") + iterator.time.slice(10).slice(0, 6), y: iterator.fhumi });
      }
      setData({
        datasets: [
          {
            label: "รายงานค่าเฉลี่ยของอุณหภูมิในแต่ละช่วงเวลาของวัน ของตู้แช่",
            data: data__,
            fill: false,
            backgroundColor: "#19578d",
            borderColor: "#19578d",
          },
        ],
      });
      const datetimeString = data__.map((item) => {
        const dateParts = item.x.split(' ')[0].split('-');
        const day = dateParts[2];
        const month = dateParts[1];
        const year = dateParts[0];
      
        return {
          Date: `${day}/${month}/${year}`,
          Time: item.x.split(' ')[1].split(':').slice(0, 2).join(':'),
          Humidity: item.y
        };
      });
      
      setCsvData([...datetimeString]);
      setChartData({
        ...chartData,
        series: [{ ...chartData.series, data: data__ }],
      });
      setLoadStatus(false)
    }

  };


  const loadRefrigerator = async () => {
    let data = localStorage.getItem("access");
    let RefrigeratorData = []

    let organization = await OrganisationByuid(JSON.parse(data)[0].msorganisationuid)


    if (JSON.parse(data)[0].msorganisationuid == "98") {
      RefrigeratorData = await Refrigerator();

    } else {
      let dataResponse = await RefrigeratorByMsOrganizationUid(JSON.parse(data)[0].msorganisationuid);
      if (JSON.parse(data)[0].type == "Admin") {
        RefrigeratorData = dataResponse
      } else {
        RefrigeratorData = dataResponse.filter((ref) => ref.mslocationuid == JSON.parse(data)[0].mslocationuid)
      }
      let newLocation = await NewLocationByuid(JSON.parse(data)[0].uid)
      // console.log('data', data);

      if (newLocation && newLocation.length > 0) {

        // console.log('newLocation' , newLocation)
        let RefrigeratorDataForm = [];
        await newLocation.forEach(element => {
          let form_ = dataResponse.filter((ref) => ref.mslocationuid == element.locationid)
          RefrigeratorDataForm.push(...form_)
        });
        // console.log('RefrigeratorDataForm-->',RefrigeratorDataForm);
        RefrigeratorData = RefrigeratorDataForm

      }
    }

    let dataFilter = [];
    let location = await getLocation()

    for await (const iterator of RefrigeratorData) {

      let mslocationData = location.filter((loc) => loc.uid == iterator.mslocationuid)

      await dataFilter.push({
        ...iterator,
        mslocationName: mslocationData[0]?.name ? mslocationData[0]?.name : '',

      })
    }

    RefrigeratorData = await dataFilter.sort(function (a, b) {
      var nameA = a.name?.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name?.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    setRefrigerator(RefrigeratorData)
    return RefrigeratorData;

  }

  const onSetTime = (value) => {
    let hour =
      (Number(value.split(":")[0]) + 1).toString().length == 1
        ? "0" + (Number(value.split(":")[0]) + 1).toString()
        : (Number(value.split(":")[0]) + 1).toString();
    setFormData({
      ...formData,
      HourFrom: value,
      HourTo: hour + ":" + value.split(":")[1],
    });
    loadData_NewTime(value, hour)

  };

  function setdate(date) {
    setFormData({ ...formData, date: date });
    loadData_Newdate(date);
  }

  const handlechange_BTtemp = (index, text) => {
    setTab(index);

    if (formData.macid != 0) {
      if (text === 'Temperature') {
        loadDataTemperature(formData.macid);
      } else {
        loadDataHumidity(formData.macid);
      }
    }
  }

  return (
    <div>
      <div className='p-4 z-20 w-full flex justify-between items-center gap-2 pb-2'>
        <div className="flex gap-2">
          {Tabs.map((text, index) => (
            <button
              key={index}
              className={`bg-transparent hover:bg-blue-500 text-white-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded ${tab === index ? 'bg-blue-500 text-white' : ''}`}
              onClick={() => {
                handlechange_BTtemp(index, text)
              }}
            >
              {text}
            </button>
          ))}
        </div>
        {!loadStatus && csvData.length > 0 && (
          <CSVLink
            data={csvData}
            filename={`Chart_Hourly_${tab === 0 ? "Temperature" : "Humidity"}.csv`}
          >
            <button className={`bg-transparent hover:bg-blue-500 text-white-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded`}>
              Download CSV
            </button>
          </CSVLink>
        )}
      </div>
      {tab === 0 ? (
        <div className="w-full p-4 bg-white">
          <div className="flex flex-row flex-wrap w-full p-4 ">
            <div className="flex flex-col w-full p-4 mb-4 sm:w-1/2 md:w-1/3 lg:w-1/5">
              <label htmlFor="temperature">temperature</label>
              <select
                name="temperature"
                id="temperature"
                className="w-full h-full px-4 py-2 border rounded-lg border-luka-dark text-luka-dark"
                onChange={(e) => {
                  loadDataTemperature(e.target.value)
                }}
                value={formData.macid}
              >
                <option value="0">Select</option>
                {refrigerator.map((data, index) => (
                  <option key={index} value={data.code}>
                    {data.code} | {data.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col w-full p-4 mb-4 sm:w-1/2 md:w-1/3 lg:w-1/5">
              <label htmlFor="">Date</label>

              <DatePicker
                selected={formData.date}
                dateFormat="dd-MM-yyyy"
                onChange={(date) => setdate(date)}
                className="w-full px-4 py-2 border rounded-lg border-luka-dark text-luka-dark "
              />
            </div>

            <div className="flex flex-col w-1/2 p-4 mb-4 md:w-1/3 lg:w-1/5">
              <label htmlFor="">HourFrom</label>
              <input
                type="time"
                value={formData.HourFrom}
                onChange={(e) => onSetTime(e.target.value)}
                className="w-full h-full px-4 py-2 border rounded-lg border-luka-dark text-luka-dark"
              />
            </div>
            <div className="flex flex-col w-1/2 p-4 mb-4 md:w-1/3 lg:w-1/5">
              <label htmlFor="">HourTo</label>
              <input
                type="time"
                value={formData.HourTo}
                disabled
                onChange={(e) =>
                  setFormData({ ...formData, HourTo: e.target.value })
                }
                className="w-full h-full px-4 py-2 bg-gray-200 border rounded-lg border-luka-dark text-luka-dark"
              />
            </div>
            <div className="flex flex-col w-full p-4 mb-4 sm:w-1/2 md:w-1/3 lg:w-1/5">
              <label htmlFor="">Search</label>
              <button
                onClick={() => loadData()}
                className="w-full px-4 py-2 text-white rounded-lg bg-luka-dark "
              >
                Search
              </button>
            </div>
          </div>
          <h1>รายงานค่าเฉลี่ยของอุณหภูมิในแต่ละช่วงเวลาของวัน ของตู้แช่</h1>

          {loadStatus ? <div className="flex justify-center text-center bg-opacity-50">
            <span className="w-full rounded-md">
              <button type="button" className="inline-flex items-center px-4 py-2 text-base font-medium leading-6 transition duration-150 ease-in-out bg-yellow-500 border border-transparent rounded-md cursor-not-allowed active:bg-gray-700" disabled="">
                <svg className="w-5 h-5 mr-3 -ml-1 animate-spin " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Processing
              </button>
            </span>
          </div> : <div className="overflow-auto bg-white">
            <Chart
              key={Math.random()}
              options={chartData.options}
              series={chartData.series}
              type="line"
              height={400}
            />
          </div>}
        </div>
      ) : (
        <div className="w-full p-4 bg-white">
          <div className="flex flex-row flex-wrap w-full p-4 ">
            <div className="flex flex-col w-full p-4 mb-4 sm:w-1/2 md:w-1/3 lg:w-1/5">
              <label htmlFor="Humidity">Humidity</label>
              <select
                name="Humidity"
                id="Humidity"
                className="w-full h-full px-4 py-2 border rounded-lg border-luka-dark text-luka-dark"
                onChange={(e) => {
                  loadDataHumidity(e.target.value);
                }}
                value={formData.macid}
              >
                <option value="0">Select</option>
                {refrigerator.map((data, index) => (
                  <option key={index} value={data.code}>
                    {data.code} | {data.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col w-full p-4 mb-4 sm:w-1/2 md:w-1/3 lg:w-1/5">
              <label htmlFor="">Date</label>

              <DatePicker
                selected={formData.date}
                dateFormat="dd-MM-yyyy"
                onChange={(date) => setdate(date)}
                className="w-full px-4 py-2 border rounded-lg border-luka-dark text-luka-dark "
              />
            </div>

            <div className="flex flex-col w-1/2 p-4 mb-4 md:w-1/3 lg:w-1/5">
              <label htmlFor="">HourFrom</label>
              <input
                type="time"
                value={formData.HourFrom}
                onChange={(e) => onSetTime(e.target.value)}
                className="w-full h-full px-4 py-2 border rounded-lg border-luka-dark text-luka-dark"
              />
            </div>
            <div className="flex flex-col w-1/2 p-4 mb-4 md:w-1/3 lg:w-1/5">
              <label htmlFor="">HourTo</label>
              <input
                type="time"
                value={formData.HourTo}
                disabled
                onChange={(e) =>
                  setFormData({ ...formData, HourTo: e.target.value })
                }
                className="w-full h-full px-4 py-2 bg-gray-200 border rounded-lg border-luka-dark text-luka-dark"
              />
            </div>
            <div className="flex flex-col w-full p-4 mb-4 sm:w-1/2 md:w-1/3 lg:w-1/5">
              <label htmlFor="">Search</label>
              <button
                onClick={() => loadData()}
                className="w-full px-4 py-2 text-white rounded-lg bg-luka-dark "
              >
                Search
              </button>
            </div>
          </div>
          <h1>รายงานค่าเฉลี่ยของอุณหภูมิในแต่ละช่วงเวลาของวัน ของตู้แช่</h1>

          {loadStatus ? <div className="flex justify-center text-center bg-opacity-50">
            <span className="w-full rounded-md">
              <button type="button" className="inline-flex items-center px-4 py-2 text-base font-medium leading-6 transition duration-150 ease-in-out bg-yellow-500 border border-transparent rounded-md cursor-not-allowed active:bg-gray-700" disabled="">
                <svg className="w-5 h-5 mr-3 -ml-1 animate-spin " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Processing
              </button>
            </span>
          </div> : <div className="overflow-auto bg-white">
            <Chart
              key={Math.random()}
              options={chartData.options}
              series={chartData.series}
              type="line"
              height={400}
            />
          </div>}
        </div>
      )}
    </div>
  );
};

export default Daily;
