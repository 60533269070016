import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
// import { nanoid } from '@reduxjs/toolkit'
// import { postAdded, postUpdated } from "../store/postApi";
import { OutofserviceByCode, MaintenanceByCode, } from '../store/api/api'
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';

const Monitor = () => {
  //   const dispatch = useDispatch();
  const temperature = useSelector((state) => state.temperature);
  const [Filter, setFilter] = useState("All");
  const [search, setSearch] = useState("");

 useEffect(() => {
    Swal.close();
  });


  const dataFilter = () => {
    let data = [];

    if (Filter === "Normal") {
      data = temperature.filter(
        (data) =>
          (Number(data.tempin)) >= (Number(data.minrang)) + 2 &&
          (Number(data.tempin)) <= (Number(data.maxrang)) - 2
      );
    }

    if (Filter === "Low-Beware") {
      data = temperature.filter(
        (data) =>
          Number(data.tempin) <= Number(data.minrang) + 2 && Number(data.tempin) > Number(data.minrang)
      );
    }

    if (Filter === "High-Beware") {
      data = temperature.filter(
        (data) =>
          Number(data.tempin) >= Number(data.maxrang) - 2 && Number(data.tempin) < Number(data.maxrang)
      );
    }


    if (Filter === "Low") {
      data = temperature.filter(
        (data) =>
          Number(data.tempin) <= Number(data.minrang)
      );
    }

    if (Filter === "High") {
      data = temperature.filter(
        (data) =>
          Number(data.tempin) >= Number(data.maxrang)
      );
    }
    if (Filter === "N/A") {
      data = temperature.filter(
        (data) =>
          data.tempin === 'N/A'
      );
    }
    if (Filter === "All") {
      data = temperature;
    }

    if (search) {
      data = temperature.filter(
        (data) =>
          data.code.includes(search) ||
          data.name.includes(search)
      );
    }

    return data;
  };


  const checkStatus = (tempin, minrang, maxrang, humidity) => {
    let res = "";
    if (tempin == 'N/A') {
      res = "No Information"
      return res
    }
    tempin = Number(tempin)
    minrang = Number(minrang)
    maxrang = Number(maxrang)
    // console.log(tempin   ,    minrang   ,    maxrang,humidity);

    if (humidity) {

    }


    if (
      tempin >= minrang + 2 &&
      tempin <= maxrang - 2
    ) {
      res = "Normal"
    } else {

      if (tempin <= minrang || tempin >= maxrang) {
        if (tempin <= minrang) {
          res = "Low"
        } else {
          res = "High"
        }
      }

      else if ((tempin <= minrang + 2 && tempin > minrang) || (tempin >= maxrang - 2 && tempin < maxrang)) {
        if (tempin >= maxrang - 2 && tempin < maxrang) {
          res = "High Beware"
        } else {
          res = "Low Beware"
        }

      }


    }



    if (humidity) {
      if (
        tempin >= minrang &&
        tempin <= maxrang
      ) {
        res = "Normal"
      } else {

        if (tempin <= minrang || tempin >= maxrang) {
          if (tempin <= minrang) {
            res = "Low"
          } else {
            res = "High"
          }
        }




      }
    }





    return res;
  }

  const onCheckClass = (result, humidity) => {
    let data = ''
    if (result == "No Information") { data = "bg-gray-300 shadow p-2 border border-gray-400 border-l-8 rounded-md" }
    else if (result == "Normal") { data = "bg-gray-100 shadow p-2 border border-luka-dark border-l-8 rounded-md" }
    else if (!humidity && result == "High Beware") { data = "bg-gray-100 shadow p-2 border border-yellow-500 border-l-8 rounded-md" }
    else if (!humidity && result == "Low Beware") { data = "bg-gray-100 shadow p-2 border border-yellow-500 border-l-8 rounded-md" }
    else if (result == "Low") { data = "bg-gray-100 shadow p-2 border border-red-800 border-l-8 rounded-md" }
    else if (result == "High") { data = "bg-gray-100 shadow p-2 border border-red-800 border-l-8 rounded-md" }
    else { data = "bg-gray-100 shadow p-2 border border-luka-dark border-l-8 rounded-md" }

    return data
  }

  return (
    <div className="flex flex-row flex-wrap justify-center p-4">
      <div className="flex flex-col w-full sm:flex-row">
        <div className="flex flex-row flex-wrap w-full p-2">

          <div className="w-1/3 p-1 cursor-pointer lg:w-1/6">
            <div
              onClick={() => setFilter("Low")}
              className="w-full h-full font-extrabold text-center text-white rounded-full lg:text-lg bg-gradient-to-t from-red-500 to-red-700 md:rounded-lg"
            >
              <b className="text-sm">Low</b>
              <p>
                {
                  temperature.filter(
                    (data) =>
                      Number(data.tempin) <= Number(data.minrang)
                  ).length
                }
              </p>
            </div>
          </div>
          <div className="w-1/3 p-1 cursor-pointer lg:w-1/6">
            <div
              onClick={() => setFilter("Low-Beware")}
              className="w-full h-full font-extrabold text-center text-white rounded-full lg:text-lg bg-gradient-to-t from-yellow-300 to-tempStatus-lowbeware md:rounded-lg"
            >
              <b className="text-sm">Low Beware</b>
              <p>
                {
                  temperature.filter(
                    (data) =>
                      Number(data.tempin) <= Number(data.minrang) + 2 && Number(data.tempin) > Number(data.minrang)
                  ).length
                }
              </p>
            </div>
          </div>
          <div className="w-1/3 p-1 cursor-pointer lg:w-1/6">
            <div
              onClick={() => setFilter("Normal")}
              className="w-full h-full font-extrabold text-center text-white rounded-full lg:text-lg bg-gradient-to-t from-blue-300 to-tempStatus-normal md:rounded-lg"
            >
              <b className="text-sm">Normal</b>
              <p>
                {
                  temperature.filter(
                    (data) =>
                      (Number(data.tempin)) >=
                      (Number(data.minrang)) + 2 &&
                      (Number(data.tempin)) <= (Number(data.maxrang)) - 2
                  ).length
                }
              </p>
            </div>
          </div>
          <div className="w-1/3 p-1 cursor-pointer lg:w-1/6">
            <div
              onClick={() => setFilter("High-Beware")}
              className="w-full h-full font-extrabold text-center text-white rounded-full lg:text-lg bg-gradient-to-t from-yellow-300 to-tempStatus-lowbeware md:rounded-lg"
            >
              <b className="text-sm">High Beware</b>
              <p>
                {
                  temperature.filter(
                    (data) =>
                      Number(data.tempin) >= Number(data.maxrang) - 2 && Number(data.tempin) < Number(data.maxrang)
                  ).length
                }
              </p>
            </div>
          </div>
          <div className="w-1/3 p-1 cursor-pointer lg:w-1/6">
            <div
              onClick={() => setFilter("High")}
              className="w-full h-full font-extrabold text-center text-white rounded-full lg:text-lg bg-gradient-to-t from-red-500 to-red-700 md:rounded-lg"
            >
              <b className="text-sm">High</b>
              <p>
                {
                  temperature.filter(
                    (data) =>
                      Number(data.tempin) >= Number(data.maxrang)
                  ).length
                }
              </p>
            </div>
          </div>
          <div className="w-1/3 p-1 cursor-pointer lg:w-1/6">
            <div
              onClick={() => setFilter("N/A")}
              className="w-full h-full font-extrabold text-center text-white rounded-full lg:text-lg bg-gradient-to-t from-green-300 to-tempStatus-na md:rounded-lg"
            >
              <b className="text-sm">N/A</b>
              <p>
                {
                  temperature.filter(
                    (data) => data.tempin === 'N/A').length
                }
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-end w-full">
          <div className="w-2/3 p-2">
            <div className="relative text-gray-600">
              <input
                type="search"
                name="serch"
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search"
                className="w-full h-10 px-5 pr-10 text-sm bg-white rounded-lg focus:outline-none"
              />
              <button
                type="submit"
                className="absolute top-0 right-0 mt-2 mr-4"
              >
                <svg
                  className="w-6 h-6 text-gray-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div className="w-1/3 p-2">
            <button
              onClick={() => dataFilter()}
              className="w-full px-4 py-2 text-sm text-white rounded-lg bg-luka-dark ">
              View Cart
            </button>
          </div>
        </div>
      </div>
      {Filter !== "All" ? (
        <div className="w-full mt-3 mb-4">
          <b
            className="px-4 py-2 text-gray-600 border border-gray-600 cursor-pointer rounded-xl"
            onClick={() => setFilter("All")}
          >
            View All
          </b>
          <h2 className="text-lg font-bold text-center text-luka-dark">{Filter}</h2>
        </div>
      ) : (
        ""
      )}

      {temperature.length > 0 ?
        <div className="flex flex-row flex-wrap justify-center w-full">
          {dataFilter().map((temperature, index) => (
            <Link
              to={`/refrigerator/${temperature.uid}`}
              className="flex flex-col w-1/2 p-2 rounded-lg sm:w-1/2 md:w-1/4 lg:w-1/6"
              key={index}
            >
              <div





                className={onCheckClass(checkStatus(temperature.tempin, temperature.minrang, temperature.maxrang, temperature?.humidity), temperature?.humidity)}

              >
                {/* <div className="text-xs text-luka-light">{temperature.code} | {temperature.name.length < 11 ?temperature.name:temperature.name.slice(0,9)+'...'}</div> */}
                <div className="text-xs text-luka-light">
                  {temperature.code} | {temperature.mslocationName} {temperature.msorganisationuid === 103 ? "| "+temperature.sn:''}
                </div>
                <div className="font-bold text-gray-500 text-md">
                  {temperature.minrang} to {temperature.maxrang} °C
                </div>
                <div className="text-2xl font-black text-luka-dark">
                  {temperature.tempin}{" "}
                </div>
                <div className="text-sm text-red-500">
                  {temperature.outofserviceDetail ? <>
                    {`OFS: ${temperature.outofserviceDetail}   `}
                  </> : <></>}
                  {temperature.maintenancestatus !== 'Pass' && temperature.maintenanceDetail && temperature.outofserviceDetail && <br />}
                  {temperature.maintenancestatus !== 'Pass' && temperature.maintenanceDetail ? <>
                    {`      MT: ${temperature.maintenanceDetail}`}
                  </> : <></>}
                </div>
                {temperature?.humidity && <div className="text-sm font-black text-luka-dark"> humidity: {temperature?.humidity} %</div>}
                {/* <div className="text-lg font-bold text-luka-light">{temperature.tempout}</div> */}
                {/* <div className="">{temperature.userangefrom} to {temperature.userangeto}</div> */}
                <div className="font-extrabold text-luka-light">
                  {/* {Math.floor(Number(temperature.tempin)) >=
                  Math.floor(Number(temperature.minrang)) + 2 &&
                  Math.floor(Number(temperature.tempin)) <=
                  Math.floor(temperature.maxrang) - 2
                  ? "Normal"
                  : (
                    (Math.floor(Number(temperature.tempin)) <=
                      Math.floor(Number(temperature.minrang)) + 1 ||
                      Math.floor(Number(temperature.tempin)) >=
                      Math.floor(temperature.maxrang) - 1) &&
                      Number(temperature.tempin) > Number(temperature.minrang) &&
                      Number(temperature.tempin) <= Number(temperature.maxrang) ? (
                      Number(temperature.tempin) <= Number(temperature.maxrang) ? "Low Beware" : "High Beware"
                    ) : (
                      Number(temperature.tempin) >= Number(temperature.maxrang)
                    ) ?
                      "High" : (Number(temperature.tempin) <= Number(temperature.minrang)) ? "Low" : "No Information"
                  )} */}
                  {checkStatus(temperature.tempin, temperature.minrang, temperature.maxrang)}

                </div>
                <div className="text-xs font-bold text-luka-dark">
                  {!temperature?.humidity && <>{temperature.opendoortime === 1
                    ? "DOOR NOT INSTALL"
                    : "DOOR NOT INSTALL"}</>}
                </div>
              </div>
            </Link>
          ))}
        </div>
        : <div className="flex flex-col items-center justify-center w-full">
          <span className="mr-5">loading...</span>

          <svg className="w-5 h-5 mr-3 -ml-1 text-black animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>}
    </div>
  );
};

export default Monitor;
