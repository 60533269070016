import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
const Profile = () => {
  let user = localStorage.getItem("access");
  user = JSON.parse(user);
  const [data, setData] = useState({});
  

 
          
//   let chart = {  series: [{
//         name: "Desktops",
//         data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
//     }],
//     options: {
//       chart: {
//         height: 350,
//         type: 'line',
//         zoom: {
//           enabled: false
//         }
//       },
//       dataLabels: {
//         enabled: false
//       },
//       stroke: {
//         curve: 'straight'
//       },
//       title: {
//         text: 'Product Trends by Month',
//         align: 'left'
//       },
//       grid: {
//         row: {
//           colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
//           opacity: 0.5
//         },
//       },
//       xaxis: {
//         categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
//       }
//     },
  
// }
  


  useEffect(() => {
    if (user.length > 0)
      setData({
        deviceid: user[0].deviceid,
        forename: user[0].forename,
        msorganisationuid: user[0].msorganisationuid,
        password: user[0].password,
        passwordNormal: user[0].passwordNormal,
        platform: user[0].platform,
        prename: user[0].prename,
        surname: user[0].surname,
        type: user[0].type,
        uid: user[0].uid,
        username: user[0].username,
      });
  }, []);
  return (
    <div className="w-full h-full bg-white ">
      <div className="flex flex-col bg-white p-4 w-full h-full">
        <div className="w-full text-center mb-4">
          <h1 className="text-luka-light text-2xl font-bold">Profile</h1>
        </div>
        <div className="w-full mb-4">
          <hr className="bg-gray-600" />
        </div>
        <div className="flex flex-row mb-4 ">
          <label className="w-3/6 text-sm" htmlFor="prename">
            Prename
          </label>
          <input
            type="text"
            name="prename"
            value={data.prename}
            onChange={(e) => setData({ ...data, prename: e.target.value })}
            className="bg-gray-300 px-4 py-2 ml-2 rounded-lg w-4/6"
          />
        </div>
        <div className="flex flex-row mb-4">
          <label className="w-3/6 text-sm" htmlFor="forename">
            forename
          </label>
          <input
            type="text"
            name="forename"
            value={data.forename}
            onChange={(e) => setData({ ...data, forename: e.target.value })}
            className="bg-gray-300 px-4 py-2 ml-2 rounded-lg w-4/6"
          />
        </div>
        <div className="flex flex-row mb-4">
          <label className="w-3/6 text-sm" htmlFor="surname">
            surname
          </label>
          <input
            type="text"
            name="surname"
            value={data.surname}
            onChange={(e) => setData({ ...data, surname: e.target.value })}
            className="bg-gray-300 px-4 py-2 ml-2 rounded-lg w-4/6"
          />
        </div>
        <div className="flex flex-row mb-4">
          <label className="w-3/6 text-sm" htmlFor="type">
            Type User
          </label>
          <input
            type="text"
            name="type"
            value={data.type}
            onChange={(e) => setData({ ...data, type: e.target.value })}
            className="bg-gray-300 px-4 py-2 ml-2 rounded-lg w-4/6"
          />
        </div>
        <div className="flex flex-row mb-4">
          <label className="w-3/6 text-sm" htmlFor="msorganisationuid">
            MsOrganisationUID
          </label>
          <input
            type="text"
            name="msorganisationuid"
            value={data.msorganisationuid}
            onChange={(e) =>
              setData({ ...data, msorganisationuid: e.target.value })
            }
            className="bg-gray-300 px-4 py-2 ml-2 rounded-lg w-4/6"
          />
        </div>
        <div className="flex flex-row mb-4">
          <label className="w-3/6 text-sm" htmlFor="username">
            Username
          </label>
          <input
            type="text"
            name="username"
            value={data.username}
            onChange={(e) => setData({ ...data, username: e.target.value })}
            className="bg-gray-300 px-4 py-2 ml-2 rounded-lg w-4/6"
          />
        </div>
        <div className="flex flex-row mb-4">
          <label className="w-3/6 text-sm" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            name="password"
            value={data.password}
            onChange={(e) => setData({ ...data, password: e.target.value })}
            className="bg-gray-300 px-4 py-2 ml-2 rounded-lg w-4/6"
          />
        </div>
      </div>


      {/* <div id="chart">
  <ReactApexChart options={chart.options} series={chart.series} type="line" height={350} />
</div> */}

    </div>
  );
};

export default Profile;
