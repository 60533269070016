import { createSlice } from "@reduxjs/toolkit";
// import { nanoid } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
const initialState = [];
// Combine all reducers.
const appReducer = combineReducers(
[]
);


 
const temperatureApi = createSlice({ 
  name: "temperature",
  initialState,
  reducers: {
    temperatureAdded: {
      reducer(state, action) {
        if (action.payload.length > 0) {
          action.payload.forEach((element) => {
            state.push({ ...element, tempin: "N/A", tempout: "N/A" });
          });
        }
      },
    },
    temperatureUpdated(state, action) {
      const existingPost = state.find(
        (post) => post.code == action.payload.code
      );
      if (existingPost) {
        existingPost.tempin = action.payload.tempin;
        existingPost.tempout = action.payload.tempout;
      }
      if (action.payload?.humidity && existingPost){
        existingPost.humidity = action.payload?.humidity;
      }
    },    
    temperatureClear(state, action) {
 
           state = [];
        
        return appReducer(state, action);
   
    },
    
  },
});
export const { temperatureAdded, temperatureUpdated,temperatureClear } = temperatureApi.actions;
export default temperatureApi.reducer;
