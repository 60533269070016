import { createSlice } from '@reduxjs/toolkit'

const initialState =[]

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    userAdded: {
      reducer(state, action) {
        state.push(action.payload)
      }
    },
    userClear(state, action) {
      state=[]
    },
  }
})
export const { userAdded,userClear } = usersSlice.actions
export default usersSlice.reducer