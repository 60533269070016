 
import {changepassword} from './../../store/api/api'
import React, { useState, useEffect, Fragment,  } from "react";
import { Dialog, Transition } from '@headlessui/react'
 const ChangPassword = () => {
  let user = localStorage.getItem('access')
  user = JSON.parse(user)
  const [check,setCheck]=useState(false)
  const [status,setStatus]=useState(false)
  
const [form , setForm] =useState({
  passwordNew:"",
  passwordConfirm:""
})


const closeModal =()=>{
  setStatus(false)
}
const openModal =()=>{
  setStatus(true)
}

const onSave =async()=>{
  if(form.passwordNew == form.passwordConfirm){
    let param = {password:form.passwordConfirm}
    let res =await changepassword(param,user[0].uid);
    setForm({
      passwordNew:"",
      passwordConfirm:""
    })
    openModal()
  }else{
    setCheck(true)
  }
  
}

  
    return (
        <div className=" p-4">
        <div className="flex flex-col flex-wrap p-4 rounded-lg justify-center items-center">
 
        <div className=" bg-white flex flex-col justify-center items-center p-4 rounded-lg">
        <h1 className="w-full mb-5 text-luka-light font-bold text-xl">เปลี่ยน รหัสผ่าน Password</h1>
        <div className="w-full h-full flex flex-col  items-center ">
  
          <div className="flex flex-col w-full">
          <div className="flex flex-row items-center justify-center">
              <div className="mr-2 text-luka-light">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                  ></path>
                </svg>
              </div>
              <div className="relative z-0 w-full mb-5">
                <input
              onChange={(e)=>setForm({...form,passwordNew:e.target.value})}
              value={form.passwordNew}
                  type="password"
                  name="name"
                  placeholder=" "
                  required
                  className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-luka-dark border-luka-light"
                />
                <label
                  htmlFor="name"
                  className="absolute duration-300 text-sm top-3 -z-10 origin-0 text-gray-400"
                >
                  password{" "}
                </label>
                <span className="text-sm text-red-600 hidden" id="error">
                  Name is required
                </span>
              </div>
            </div>
       
            <div className="flex flex-row items-center justify-center">
              <div className="mr-2 text-luka-light">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                  ></path>
                </svg>
              </div>
              <div className="relative z-0 w-full mb-5">
                <input
              onChange={(e)=>setForm({...form,passwordConfirm:e.target.value})}
              value={form.passwordConfirm}

                  type="password"
                  name="name"
                  placeholder=" "
                  required
                  className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-luka-dark border-luka-light"
                />
                <label
                  htmlFor="name"
                  className={`absolute duration-300 text-sm top-3 -z-10 origin-0 text-gray-400 
                  ${check? "text-red-600":""}`}
                >
                  password{" "}
                </label>
                <span className="text-sm text-red-600 hidden" id="error">
                  Name is required
                </span>
              </div>
            </div>
       

            <div className="w-full flex justify-center items-center">
            <button
            onClick={()=>onSave()}
            className="bg-luka-dark text-white rounded-lg px-4 py-1">Submit</button>
            </div>
          </div>
  
        </div>


        </div>
         </div>

         <Transition appear show={status} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-blue-200 shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-blue-900"
                >
                 success
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                  เปลี่ยน รหัสผ่าน Password สำเร็จ
                  </p>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-300 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                    onClick={closeModal}
                  >
                    OK
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
  
      </div>
    )
}
export default ChangPassword