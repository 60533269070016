import React, { useState, useEffect } from "react";
import {
  RefrigeratorimgBymsrefrigeratoruid,
  RefrigeratorByUid,
} from "../../store/api/api";
import mqtt from "mqtt";

import { Link, useParams } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { HubConnectionBuilder } from "@microsoft/signalr";
import moment from "moment";

const TemperatureMonitoring = () => {
  const [connection, setConnection] = useState(null);
  const [dataTemp, setDataTemp] = useState({});
  const [tempin, setTempin] = useState([]);
  const [tempDate, setTempDate] = useState([]);


  let { id } = useParams();
  // useEffect(() => {
  //   const connect = new HubConnectionBuilder()
  //     .withUrl("https://apps.telecorpthailand.com/signalRHub")
  //     .withAutomaticReconnect()
  //     .build();
  //   setConnection(connect);
  // }, []);

  // useEffect(() => {
  //   if (connection) {
  //     let temperatureData = [];
  //     let temperatureDate = [];
  //     connection
  //       .start()
  //       .then(() => {
  //         connection.on("UpdateDashboardAsync", (message) => {
  //           if (message.macid == id) {
  //             temperatureData.push(message.ftempin)
  //             temperatureDate.push(moment(new Date()).format("HH:mm:ss"))
  //             setDataTemp(message)
  //             setTempin(temperatureData);
  //             setTempDate(temperatureDate)
  //           }

  //         });
  //       })
  //       .catch((error) => console.log(error));
  //   }
  // }, [connection]);



  useEffect(() => {
    loadMQTT()
  }, [])
  


  const loadMQTT = () => {
    let temperatureData = [];
    let temperatureDate = [];
    let client = mqtt.connect('wss://api-temp.telecorp.co.th', { host: 'api-temp.telecorp.co.th', port: 9001, username: 'admintelecorp', password: '11681168', rejectUnauthorized: false })
    client.on('connect', async (res) => {
      client.subscribe(`#`)
    });
    
    client.on('message', async (topic, payload) => {
      let dataRefigurater;
      let tempin;
      let code;
      try {
        // console.log(payload.toString(),topic);
        tempin = JSON.parse('{' + payload.toString().slice(14, 1000))
        code = payload.toString().slice(8, 12)
        dataRefigurater = { ...tempin, code: code }
      } catch (error) {
        try {
          let dataPayload = JSON.parse(payload.toString())
          dataRefigurater = dataPayload
        } catch (error1) {
          console.log(error1);
        }



      }

      try {
   
         if (dataRefigurater.code == id) {
                console.log('dataRefigurater------->',dataRefigurater)
                temperatureData.push(dataRefigurater.tempin)
                temperatureDate.push(moment(new Date()).format("HH:mm:ss"))


                console.log('temperatureData,temperatureDate-->',temperatureData,temperatureDate,);
                setDataTemp(dataRefigurater)
                setTempin(temperatureData);
                setTempDate(temperatureDate)
              }
      } catch (error) {
        console.log(error);
      }





    })

}





const data = {
  labels: tempDate,
  datasets: [
    {
      label: "Chart Time",
      data: tempin,
      fill: false,
      backgroundColor: "#19578d",
      borderColor: "#19578d",
    },
  ],
};
const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};
return (
  <div className="p-4 bg-white">
    <div className="flex flex-row flex-wrap p-4">
      <div className="flex flex-col w-full p-2 sm:border-r-2 sm:border-gray-600 sm:w-1/2">
        <div className="flex flex-row w-full p-3 mb-3 bg-blue-300 rounded-lg shadow-inner">
          <div className="flex-1">{dataTemp.probe1 ? dataTemp.probe1 : 'N/A'}</div>
          <div className="flex-1 text-right">Max</div>
        </div>
        <div className="flex flex-row w-full p-3 mb-3 bg-green-700 rounded-lg shadow-inner">
          <div className="flex-1">{dataTemp.probe2 ? dataTemp.probe2 : 'N/A'}</div>
          <div className="flex-1 text-right">Max</div>
        </div>
        <div className="flex flex-row w-full p-3 mb-3 bg-green-300 rounded-lg shadow-inner">
          <div className="flex-1">{dataTemp.probe3 ? dataTemp.probe3 : 'N/A'}</div>
          <div className="flex-1 text-right">Max</div>
        </div>
        <div className="flex flex-row w-full p-3 mb-3 bg-yellow-600 rounded-lg shadow-inner">
          <div className="flex-1">{dataTemp.probe4 ? dataTemp.probe4 : 'N/A'}</div>
          <div className="flex-1 text-right">Max</div>
        </div>
        <div className="flex flex-row w-full p-3 mb-3 bg-red-300 rounded-lg shadow-inner">
          <div className="flex-1">{dataTemp.probe5 ? dataTemp.probe5 : 'N/A'}</div>
          <div className="flex-1 text-right">Max</div>
        </div>

        <div className="flex flex-row w-full p-3 mb-3 text-white rounded-lg shadow-inner bg-luka">
          <div className="flex flex-col w-full">
            <div className="flex flex-row w-full">
              <p className="flex-1">Probe 1 = {dataTemp.probe1 ? dataTemp.probe1 : 'N/A'}</p>
              <p className="flex-1">Probe 2 = {dataTemp.probe2 ? dataTemp.probe2 : 'N/A'}</p>
            </div>
            <div className="flex flex-row w-full">
              <p className="flex-1">Probe 3 = {dataTemp.probe3 ? dataTemp.probe3 : 'N/A'}</p>
              <p className="flex-1">Probe 4 = {dataTemp.probe4 ? dataTemp.probe4 : 'N/A'}</p>
            </div>
            <div className="flex flex-row w-full">
              <p className="flex-1">Probe 5 = {dataTemp.probe5 ? dataTemp.probe5 : 'N/A'}</p>
            </div>

            <p className="w-1/2">Humidity In = {dataTemp.humi}%</p>
          </div>
        </div>
      </div>
      <div className="w-full p-2 sm:border-l-2 sm:border-gray-600 sm:w-1/2">
        <Line data={data} options={options} />
      </div>
    </div>
  </div>
);
};

export default TemperatureMonitoring;
