import React from 'react'
import { NavLink } from "react-router-dom";

const MenuReport = () => {
    return (
<div className="w-full h-full">
      <nav className=" w-full h-full">
        <div className="container p-6 mx-auto">
          <h1 className="block text-2xl font-bold text-center text-luka-dark dark:text-white lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300">
            Menu
          </h1>

          {/* <div className="flex items-center justify-center mt-6 text-gray-600 capitalize dark:text-gray-300">
                        <NavLink to="/Home" activeClassName=" border-b-2 border-blue-500" className="border-b-2 border-transparent hover:text-gray-800 dark:hover:text-gray-200 hover:border-blue-500 mx-1.5 sm:mx-6" >
                            home
                        </NavLink>
                        <NavLink to="/Monitor" activeClassName=" border-b-2 border-blue-500" className="border-b-2 border-transparent hover:text-gray-800 dark:hover:text-gray-200 hover:border-blue-500 mx-1.5 sm:mx-6" >
                            Monitor
                        </NavLink>
                        <NavLink to="/login" activeClassName=" border-b-2 border-blue-500" className="border-b-2 border-transparent hover:text-gray-800 dark:hover:text-gray-200 hover:border-blue-500 mx-1.5 sm:mx-6" >
                            Login
                        </NavLink>
                    </div> */}

      
            <div className="px-7 w-full ">
              <div className="flex flex-wrap w-full justify-between  items-center">
                <div className="w-1/2  group">
                  <NavLink
                    to="/report/today/0"
                    activeClassName="text-indigo-500"
                    className="flex items-end justify-center text-center mx-auto px-4 pt-2 text-gray-400 group-hover:text-luka-light w-full sm:w-1/2 md:w-1/3"
                  >
                    <span className="px-1 pt-1 pb-1 flex flex-col justify-center items-center w-full">
                 <div className="bg-luka-light text-white w-full justify-center items-center flex p-2 rounded-lg mb-3 h-20 ">
                 <svg className="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path></svg>
                 </div>
                      {/* <i className="far fa-home  w-10 h-10 text-2xl pt-1 mb-1 block"></i> */}
                      <span className="block text-xs pb-2 text-black h-16">
                      Daily Report
                      </span>
                      <span className="block w-5 mx-auto h-1 group-hover:bg-luka-light rounded-full"></span>
                    </span>
                  </NavLink>
                </div>
                <div className="w-1/2  group">
                  <NavLink
                    to="/report/hour/0"
                    activeClassName="text-indigo-500"
                    className="flex items-end justify-center text-center mx-auto px-4 pt-2 text-gray-400 group-hover:text-luka-light w-full sm:w-1/2 md:w-1/3"
                  >
                    <span className="px-1 pt-1 pb-1 flex flex-col justify-center items-center w-full">
             <div className="bg-luka-light text-white w-full justify-center items-center flex p-2 rounded-lg mb-3 h-20 ">
             <svg className="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path></svg>
             </div>
                      {/* <i className="far fa-compass  w-10 h-10 text-2xl pt-1 mb-1 block"></i> */}
                      <span className="block text-xs pb-2 text-black h-16">
                      Hourly Report
                      </span>
                      <span className="block w-5 mx-auto h-1 group-hover:bg-luka-light rounded-full"></span>
                    </span>
                  </NavLink>
                </div>
                <div className="w-1/2  group">
                  <NavLink
                    to="/report/week/0"
                    activeClassName="text-indigo-500"
                    className="flex items-end justify-center text-center mx-auto px-4 pt-2 text-gray-400 group-hover:text-luka-light w-full sm:w-1/2 md:w-1/3"
                  >
                    <span className="px-1 pt-1 pb-1 flex flex-col justify-center items-center w-full">
             <div className="bg-luka-light text-white w-full justify-center items-center flex p-2 rounded-lg mb-3 h-20 ">
             <svg className="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path></svg>
             </div>
                      {/* <i className="far fa-compass  w-10 h-10 text-2xl pt-1 mb-1 block"></i> */}
                      <span className="block text-xs pb-2 text-black h-16">
                      Weekly Report
                      </span>
                      <span className="block w-5 mx-auto h-1 group-hover:bg-luka-light rounded-full"></span>
                    </span>
                  </NavLink>
                </div>
                <div className="w-1/2  group">
                  <NavLink
                    to="/report/month/0"
                    activeClassName="text-indigo-500"
                    className="flex items-end justify-center text-center mx-auto px-4 pt-2 text-gray-400 group-hover:text-luka-light w-full sm:w-1/2 md:w-1/3"
                  >
                    <span className="px-1 pt-1 pb-1 flex flex-col justify-center items-center w-full">
              <div className="bg-luka-light text-white w-full justify-center items-center flex p-2 rounded-lg mb-3 h-20 ">
              <svg className="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path></svg>
              </div>
                      {/* <i className="far fa-cog  w-10 h-10 text-2xl pt-1 mb-1 block"></i> */}
                      <span className="block text-xs pb-2 text-black h-16">
                      Monthly Report
                      </span>
                      <span className="block w-5 mx-auto h-1 group-hover:bg-luka-light rounded-full"></span>
                    </span>
                  </NavLink>
                </div>


                <div className="w-1/2  group">
                  <NavLink
                    to="/report/kpi"
                    activeClassName="text-indigo-500"
                    className="flex items-end justify-center text-center mx-auto px-4 pt-2 text-gray-400 group-hover:text-luka-light w-full sm:w-1/2 md:w-1/3"
                  >
                    <span className="px-1 pt-1 pb-1 flex flex-col justify-center items-center w-full">
              <div className="bg-luka-light text-white w-full justify-center items-center flex p-2 rounded-lg mb-3 h-20 ">
              <svg className="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path></svg>
              </div>
                      {/* <i className="far fa-cog  w-10 h-10 text-2xl pt-1 mb-1 block"></i> */}
                      <span className="block text-xs pb-2 text-black h-16">
                      KPI Report
                      </span>
                      <span className="block w-5 mx-auto h-1 group-hover:bg-luka-light rounded-full"></span>
                    </span>
                  </NavLink>
                </div>


               </div>

              
            </div>
 
        </div>
      </nav>
    </div>
    )
}

export default MenuReport
