// import logo from './logo.svg';
import "./App.css";
import {
  BrowserRouter,
  Switch,
  Route,
  // Router,
  Redirect,
  // useRouteMatch,
  // useParams
} from "react-router-dom";
import Monitor from "./components/Monitor";
import Login from "./auth/Login";
// import { Nav } from "./components/structure/Nav";
import Home from "./components/Home";
import Refrigerator from "./components/refrigerator/Refrigerator";
import General from "./components/structure/General";
import Month from "./components/report/Month";
import Daily from "./components/report/Daily";
import Today from "./components/report/Today";
import Week from "./components/report/Week";
import Kpi from "./components/report/Kpi";

import MenuReport from "./components/report/MenuReport";
import TemperatureMonitoring from "./components/refrigerator/TemperatureMonitoring";
import AlarmList from "./components/refrigerator/AlarmList";
import Profile from "./components/profile/Profile";
import Service from "./components/serviceRepair/Service";
import ServiceSetupCompany from "./components/serviceCompany/ServiceSetupCompany";
import ChangePassword from './components/profile/ChangPassword'
import EditRefrigerator from './components/refrigerator/EditRefrigerator'
function App() {
  let token = localStorage.getItem("accessData");

  return (
    <BrowserRouter>
      {/* <Router basename={'/luka-uat'}> */}
      <div className="w-full h-full min-h-screen font-mono bg-gray-100">
        {/* <Redirect from="/" to="/Monitor" />   */}

        <Switch>
          <Route exact path="/">
            {token ? <Redirect to="/Monitor" /> : <Redirect to="/login" />}
          </Route>
          <Route strict path="/login">
            <Login />
          </Route>
        </Switch>
        
        <Switch>
          <Route path="/Monitor">
            <General>
              <Monitor />
            </General>
          </Route>
        </Switch>

        <Switch>
          <Route path="/login/:id">
            <Login />
          </Route>
        </Switch>
        <Switch>
          <Route path="/refrigerator/:id">
            <General>
              <Refrigerator></Refrigerator>
            </General>
          </Route>
          <Route path="/TemperatureMonitoring/:id">
            <General>
              <TemperatureMonitoring></TemperatureMonitoring>
            </General>
          </Route>
          <Route path="/AlarmList/:id">
            <General>
              <AlarmList></AlarmList>
            </General>
          </Route>
          <Route path="/Profile">
            <General>
              <Profile></Profile>
            </General>
          </Route>
          <Route path="/EditRefrigerator/:id">
            <General>
              <EditRefrigerator></EditRefrigerator>
            </General>
          </Route>
          <Route path="/ChangePassword">
            <General>
              <ChangePassword></ChangePassword>
            </General>
          </Route>
          
        </Switch>

        <Switch>
          <Route path="/report/menu">
            <General>
              <MenuReport></MenuReport>
            </General>
          </Route>
        </Switch>

        <Switch>
          <Route exact path="/report/today/:id">
            <General>
              <Today></Today>
            </General>
          </Route>

          <Route path="/report/hour/:id">
            <General>
              <Daily></Daily>
            </General>
          </Route>

          <Route exact path="/report/week/:id">
            <General>
              <Week></Week>
            </General>
          </Route>

          <Route path="/report/month/:id">
            <General>
              <Month></Month>
            </General>
          </Route>

          <Route path="/report/Kpi">
            <General>
              <Kpi></Kpi>
            </General>
          </Route>
        </Switch>

        <Switch>
          <Route exact path="/home">
            <Home></Home>
          </Route>
        </Switch>
        
        <Switch>
          <Route exact path="/service">
            <General>
              <Service></Service>
            </General>
          </Route>
        </Switch>

        <Switch>
          <Route exact path="/servicecompany">
            <General>
              <ServiceSetupCompany></ServiceSetupCompany>
            </General>
          </Route>
        </Switch>
      </div>
      {/* </Router> */}
    </BrowserRouter>
  );
}

export default App;
