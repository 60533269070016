import React from "react";

const Kpi = () => {
  const data = [
    {
      description: "ร้อยละการชำรุดของตู้แช่ต่อปี",
      range: "< 1%",
      lastYear: 2021,
      oneYearAgo: 2020,
      twoYearAgo: 2019,
      lastYearValue:'NA',
      oneYearAgovalue:'NA',
      twoYearAgoValue:'NA',
    },
    {
      description: "Downtime การชำรุดของตู้แช่",
      range: "< 15 วัน",
      lastYear: 2021,
      oneYearAgo: 2020,
      twoYearAgo: 2019,
      lastYearValue:'NA',
      oneYearAgovalue:'NA',
      twoYearAgoValue:'NA',
    },
    {
      description: "	ช่วงอุณหภูมิเฉลี่ยของสภาพแวดล้อมภายนอกตู้แช่/ตู้เย็น",
      range: "< 35 C",
      lastYear: 2021,
      oneYearAgo: 2020,
      twoYearAgo: 2019,
      lastYearValue:'22.51 C',
      oneYearAgovalue:'22.31 C',
      twoYearAgoValue:'25.21 C',
    },
    {
      description: "	ช่วงความชื้นเฉลี่ยของสภาพแวดล้อมภายนอกตู้แช่/ตู้เย็น",
      range: "< 70 %RH",
      lastYear: 2021,
      oneYearAgo: 2020,
      twoYearAgo: 2019,
      lastYearValue:'63.76 %RH',
      oneYearAgovalue:'68.58 %RH',
      twoYearAgoValue:'62.95 %RH',
    },
    {
      description: "การเปิดประตูตู้แช่เฉลี่ยต่อวัน",
      range: "< 1%",
      lastYear: 2021,
      oneYearAgo: 2020,
      twoYearAgo: 2019,
      lastYearValue:'300 ครั้ง',
      oneYearAgovalue:'291 ครั้ง',
      twoYearAgoValue:'399 ครั้ง',
    },
    {
      description: "ร้อยละของตู้แช่ที่ผ่านการสอบเทียบ (Calibration)",
      range: "100%",
      lastYear: 2021,
      oneYearAgo: 2020,
      twoYearAgo: 2019,
      lastYearValue:'NA',
      oneYearAgovalue:'NA',
      twoYearAgoValue:'NA',
    },
    {
      description: "ร้อยละของเทอร์โมมิเตอร์ที่ผ่านการสอบเทียบ (Calibration)",
      range: "100%",
      lastYear: 2021,
      oneYearAgo: 2020,
      twoYearAgo: 2019,
      lastYearValue:'NA',
      oneYearAgovalue:'NA',
      twoYearAgoValue:'NA',
    },
    {
      description: "ค่าเฉลี่ยของอุณหภูมิภายในตู้แช่ แต่ละช่วงอุณหภูมิการใช้งาน",
      range: "< 1%",
      lastYear: 2021,
      oneYearAgo: 2020,
      twoYearAgo: 2019,
      lastYearValue:'',
      oneYearAgovalue:'',
      twoYearAgoValue:'',
    },
  
    {
        description: "ช่วงอุณหภูมิที่ 15 ถึง 25 องศำเซลเซียส",
        range: "15 ถึง 25 C",
        lastYear: 2021,
        oneYearAgo: 2020,
        twoYearAgo: 2019,
    lastYearValue:'NA',
    oneYearAgovalue:'NA',
    twoYearAgoValue:'NA',
      },
      {
        description: "ช่วงอุณหภูมิที่ 2 ถึง 8 องศำเซลเซียส",
        range: "2 ถึง 8 C",
        lastYear: 2021,
        oneYearAgo: 2020,
        twoYearAgo: 2019,
        lastYearValue:'4.73 C',
        oneYearAgovalue:'4.89 C',
        twoYearAgoValue:'4.93 C',
      },
      {
        description: "ช่วงอุณหภูมิที่ -10 ถึง -40 องศำเซลเซียส",
        range: "-10 ถึง -40 C",
        lastYear: 2021,
        oneYearAgo: 2020,
        twoYearAgo: 2019,
        lastYearValue:'-12.92 C',
        oneYearAgovalue:'-13.55 C',
        twoYearAgoValue:'-13.72 C',
      },
      {
        description: "ช่วงอุณหภูมิที่ -60 ถึง -86 องศำเซลเซียส",
        range: "-60 ถึง -86 C",
        lastYear: 2021,
        oneYearAgo: 2020,
        twoYearAgo: 2019,
        lastYearValue:'NA',
        oneYearAgovalue:'NA',
        twoYearAgoValue:'NA',
      },

    {
      description: "จำนวนครั้งในการแจ้งเตือนในแต่ละวัน",
      range: "< 100 ครั้ง",
      lastYear: 2021,
      oneYearAgo: 2020,
      twoYearAgo: 2019,
      lastYearValue:'30 ครั้ง',
      oneYearAgovalue:'23 ครั้ง',
      twoYearAgoValue:'1 ครั้ง',
    },
  ];
  return (
 <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
              <tr>
                <th
                 scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">No</th>
                <th
                 scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ตัวชี้วัด</th>
                <th
                 scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">เป้า</th>
                <th
                 scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">2021</th>
                <th
                 scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">2020</th>
                <th
                 scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">2019</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
                {data.map((data,index)=>(
   <tr key={index}>
   <td className="px-6 py-4 whitespace-nowrap font-light text-gray-600">{index + 1}</td>
   <td className="px-6 py-4 whitespace-nowrap font-light text-gray-600">{data.description}</td>
   <td className="px-6 py-4 whitespace-nowrap font-light text-gray-600">{data.range}</td>
   <td className="px-6 py-4 whitespace-nowrap font-light text-gray-600">{data.lastYearValue}</td>
   <td className="px-6 py-4 whitespace-nowrap font-light text-gray-600">{data.oneYearAgovalue}</td>
   <td className="px-6 py-4 whitespace-nowrap font-light text-gray-600">{data.twoYearAgoValue}</td>
 </tr>
                ))}
           
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
 
  );
};

export default Kpi;
